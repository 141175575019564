.poll-bg {
  background-color: #31197C;
  height: calc(100vh);
  overflow-y: auto;
}

span.li-dot {
  color: #31197C;
  background-color: #31197C;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.quiz-card-border {
  width: 100% !important;
  border: 1px solid lightgray;
  background-color: #fff2e6;
  border-radius: 12px;
  padding-bottom: 2px;
  padding-top: 2px;
  margin-left: 0%;
}

.poll-bg-pr {
  background-color: #31197C;
  height: calc(50vh);
  overflow-y: auto;
}

.read-also {
  font-size: 16px;
  font-family: Open Sans !important;
  color: #311942;
  font-weight: 600;
  border: 3px solid #ff942c;
  padding: 5px 10px;
  border-radius: 20px;
}
.latest-art {
  font-size: 20px;
  font-weight: 600;
  color: #FF942C;
  margin-left: 5px;
  margin-bottom: 15px;
}
.favbits-author {
  margin-top: 4px;
  width: 35px;
  margin-bottom: 4px;
}
/* .latest-art-card {
  width: 99%;
  height:auto;
  margin-bottom: 10px;
  background: #fffffb;
  border-radius: 7px;
  border: none;
  box-shadow: 2px 2px 6px #bebebe,
      -2px -2px 6px #ffffff;
} */
.latest-art-card {
  width: 99%;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  background: #fffffb;
  border-radius: 7px;
  border: none;
  box-shadow: 2px 2px 6px #bebebe, -2px -2px 6px #ffffff;
}
.latest-art-card .row {
  height: 100%;
}
.favbits-title {
  font-size: 15px;
  color: black;
  text-align: left;
  margin-top: 7px;
}
.favbits-title-latest {
  font-size: 15px;
  color: black;
  text-align: left;
}
.verify {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-align {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.digit-width {
  width: 40px;
  height: 40px;
  color: black;
  border-radius: 6px;
  height: 3.2rem;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: bold;
  font-size: 25px;
}

.sceem-logo-download-new-pr {
  width: 120px;
  margin-top: 0.8rem;
  margin-left: 0.8rem;
}

.html-text-new h1 {
  font-size: 1.25rem !important;
  color: black;
  font-weight:600;
}

.html-text-new ol {
  list-style-type: decimal;
}

.html-text-new ul {
  list-style-type: disc;
}
.html-text-new-des h1 {
  font-size: 1.15rem !important;
  color: black;
  font-weight:600;
}

.html-text-new-des ol {
  list-style-type: decimal;
}

.html-text-new-des ul {
  list-style-type: disc;
  margin-left: 0.9rem;
}

.powered-by-nebutech-new-pr {
  margin-top: 0.8rem;
  margin-left: 65%;
  margin-bottom: 5px;
  width: 120px;
}

.opp-heading {
  margin-left: 3.2rem;
}

.app-border-top-pr {
  margin-left: 4%;
  margin-right: 4%;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #31197C;
  border-radius: 15px;
  width: 92%;
  height: 100%;
}

.article-chain-text-pr {
  margin-top: 5.5%;
  font-size: 14px;
  font-family: Open Sans !important;
  color: #31197C;
  font-weight: 600;
}

.box-text-lp-pr {
  margin-top: 0px;
  font-size: 14px;
  color: #31197C;
  font-family: Open Sans !important;
  font-weight: 600;
}

.topblue_bar {
  z-index: 1;
  overflow: hidden;
  background-color: #31197C;
  position: fixed;
  top: -5px;
  width: 100%;
  height: 70px;
}

.enable-poll-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  border: none;
  min-height: calc(85vh);
}

.answers-card {
  margin-top: 8vh;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.expired-t {
  font-family: Open Sans !important;
}

.campaign-name {
  font-size: 20px;
  font-weight: 600;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 10px;
}

.adv-sm {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.8px;
  color: #000000;
}

.campaign-url {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 14px;
  font-weight: 600;
  color: #31197C;
  cursor: pointer;
}

.shared-adv {
  display: flex;
  flex-direction: column;
  height: 80vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.expired-card {
  margin-top: -4vh;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.topic-top {
  margin-top: 2vh;
}

.topic-name {
  font-family: Open Sans !important;
  display: flex;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  padding: 0 4rem;
  font-size: 17px;
  line-height: 18px;
  letter-spacing: 0.8px;
  color: #FF942C;
}

.poll-result-card {
  padding: 0.5rem 1.2rem 1rem 1.2rem;
  height: auto;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  margin: 0 auto;
}

.poll-answer-card {
  padding: 0.5rem 0.5rem 1rem 1.8rem;
  height: auto;
  width: 94%;
  background-color: white;
  border-radius: 20px;
  margin: 0 auto;
}

.poll-answer-card-post {
  padding: 0.5rem 0.5rem 1rem 1.8rem;
  height: auto;
  background: #ebfcfc;
  /* background-color: #fff2e6; */
  border-radius: 20px;
  margin: 0 0.4rem;
}

.poll-result-card-post {
  padding: 0.5rem 1.2rem 1rem 0;
  height: auto;
  /* background: #e6fafc; */
  background-color: #ebfcfc;
  border-radius: 20px;
  margin: 0 0.4rem;
}

.q-submit-btn {
  width: 4.5rem;
  height: 32px;
  color: white !important;
  padding-top: -5px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  font-family: Open Sans !important;
  background: #31197C !important;
}

.q-submit-btn:hover {
  color: white !important;
  background: #31197C !important;
}

.poll-question-post {
  padding: 0.5rem;
  margin-top: 0.2rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center !important;
  letter-spacing: 0.8px;
  color: #000000;
}

.question_post {
  font-weight: bold;
  font-family: Raleway, sans-serif;
  font-size: 20px;
}

.poll-question {
  padding: 0.2rem;
  margin-top: 0.2rem;
  font-family: Open Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.8px;
  color: #000000;
}

.poll-question-r {
  padding: 0.2rem;
  margin-left: 1rem;
  margin-right: 0.5rem;
  margin-top: 0.2rem;
  font-family: Open Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.8px;
  color: #000000;
}

.polloptions-card {
  margin-top: 1rem;
  width: 98%;
  height: 50px;
  display: flex;
  padding: 10px 6px 10px 6px;
  border-radius: 10px;
  border: 1px solid lightgrey;
}

.polloptions-card-post {
  margin-top: 1rem;
  width: 98%;
  height: 50px;
  display: flex;
  background: white;
  padding: 2px 5px;
  border-radius: 10px;
  border: 1px solid lightgrey;
}

.selected {
 /* background: #FFE6CE !important; */
  border: 2px solid #DB6C00;
}

.poll-text {
  font-size: 15px;
  font-family: Open Sans !important;
  align-items: flex-start;
  font-weight: 600;
  float: left;
  justify-content: left;
  margin-top: auto;
  margin-bottom: auto;
  animation-duration: 3s;
  animation-name: slidein;
}

.poll-submit-btn {
  width: 8rem;
  height: 50px;
  color: black;
  font-family: Open Sans !important;
  border-radius: 6px;
  background: white !important;
}

.poll-submit-btn:hover {
  color: black;
  background: white;
}

.goback-poll-btn {
  width: 10rem;
  height: 50px;
  color: black;
  font-family: Open Sans !important;
  border-radius: 6px;
  background: white !important;
}

.goback-poll-btn:hover {
  color: black;
  background: white;
}

.login-logo {
  position: fixed;
  width: 145px;
  height: 45px;
  top: 10px;
  left: 19px;
}

.home-image-lan {
  margin-top: 4.5%;
  width: 90%;
  height: 100%;
  display: inline;
}

.loading-animation {
  animation: loadingAnimation 1s ease-in-out;
}

@keyframes loadingAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.background-lp {
  width: 100%;
  height: 14%;
  font-family: Open Sans;
}

.article-ratings-text {
  font-size: 16px;
  font-family: Open Sans;
  color: #31197C;
  font-weight: 600;
}

.listen-T {
  margin-top: 1rem;
}

.latest-ratings {
  font-size: 20px;
  font-family: Open Sans;
  color: #31197C;
  font-weight: 600;
}

.latest-ratings-text-sm {
  font-size: 20px;
  font-family: Open Sans;
  color: #31197C;
  font-weight: 600;
}

.latest-avg-rating {
  font-size: 20px;
  font-family: Open Sans;
  color: #31197C;
  font-weight: 600;
}

/* .vertical-center-container { 
  height: 200px;
  position: relative;
  border: 3px solid green; 
}

.vertically-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
} */
.card-b {
  width: 80%;
}

.feddback_card {
  width: 100%;
  position: relative;
}

.feed-ratings {
  padding-left: 15%;
  width: 100%;
  max-width: 100%;
  text-align: left;
  font-family: Open Sans;
  vertical-align: middle;
}

.feddback_card-art {
  width: 83%;
  position: relative;
}

.feed-ratings-art {
  padding-left: 4%;
  width: 100%;
  max-width: 100%;
  text-align: left;
  font-family: Open Sans;
  vertical-align: middle;
}

.rating-v-align {
  margin-top: 1rem;
}

.rating-v-align-art {
  margin-top: 2.2rem;
}

.milestone-img-art {
  width: 90%;
}

.avgRatingCount-art {
  position: absolute;
  font-size: 22px;
  font-weight: 600;
  top: 2.3rem;
  left: 21.8%;
}

.avgRatingCount {
  position: absolute;
  font-size: 25px;
  font-weight: 600;
  top: 1.8rem;
  left: 28%;
}

.visit-btn {
  border: #31197C;
  background: #31197C !important;
  width: auto;
  height: 3rem;
  padding: auto;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  color: white;
  display: flex;
  white-space: nowrap;
  font-family: Open Sans;
  text-align: center;
}

.visit-btn:hover {
  border: #31197C;
  background: #31197C !important;
  width: auto;
  height: 3rem;
  padding: auto;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  color: white;
  display: flex;
  white-space: nowrap;
  text-align: center;
}

.card-padding {
  padding: 10px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: Open Sans;
  border: none;
  border-radius: 0;
  border-right: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  /* box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.2); */
}

.desc {
  font-family: Open Sans;
  font-size: 16px;
  height: 70px
}

.rating-date {
  font-family: Open Sans;
  font-size: 14px;
}

.anonymous {
  font-size: 18px;
  font-family: Open Sans;
}

.rat-bg {
  position: absolute;
  left: 10%;
}

.raters {
  font-size: 14px;
  font-family: Open Sans;
  margin-left: 16px;
  font-weight: 600;
}

/* .enable-center {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  width: 100%;
  border: none;
  min-height: 100%;
} */

.app-border-lp {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 20rem;
  padding: 1rem;
  margin: auto;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #31197C;
  border-radius: 10px;
  height: 100%;
}

.sceem-logo-download-lp {
  width: 20vh;
  margin-left: 2.5rem;
}

.powered-by-nebutech-lp {
  width: 25vh;
  margin-right: 10px;
}

.p-nebutech-right {
  text-align: right !important;
}

.download-text-lp {
  margin-top: -0.5rem;
  font-size: 12px;
  color: black;
  font-weight: 500;
  font-family: Open Sans !important;
}

.googleplay-lp {
  margin-top: 5px;
  width: 15%;
}

.l-page-top {
  position: absolute;
  top: 15vh;
  color: #31197C;
  z-index: 1;
  right: 2.8rem;
  text-align: right;
  font-size: 32px;
  font-weight: 600;
  font-family: Open Sans;
}

.l-page-text {
  position: absolute;
  top: 23vh;
  color: grey;
  z-index: 1;
  right: 2.85rem;
  left: 71%;
  text-align: right;
  font-size: 2vh;
  font-weight: normal;
  font-family: Open Sans;
}

.about-sceem {
  margin-top: 2rem;
  width: 75%;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.about-sceem-top {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sceem-4es {
  width: 100%;
  height: 100%;
}

.about-sceem-heading {
  margin-top: 2rem;
  font-size: 25px;
  font-weight: 600;
  font-family: Open Sans;
  margin-left: 3.5rem;
  color: #31197C;
}

.about-sceem-textsm {
  margin-top: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  font-family: Open Sans;
  margin-left: 3.5rem;
  color: black;
}

.vision-bg {
  background-color: #31197C;
  height: 100%;
  color: white;
}

.our-topics {
  position: absolute;
  margin-top: -3px;
  width: 16%;
  background-color: #31197C;
  left: 42%;
  right: 40%;
  text-align: center;
  color: white;
  font-size: 25px;
  font-weight: 600;
  font-family: Open Sans;
}

.our-vision {
  position: absolute;
  margin-top: -3px;
  width: 30%;
  background-color: #31197C;
  left: 36%;
  right: 30%;
  text-align: center;
  color: white;
  font-size: 25px;
  font-weight: 600;
  font-family: Open Sans;
}

.vision {
  padding: 2rem 1.5rem;
  width: 85%;
  margin: 0rem auto 1rem auto;
  border: 2px solid #FF942C;
  border-radius: 20px;
}

.commitment-top {
  margin-top: 8vh;
}

.vision-text {
  font-size: 15px;
  font-weight: normal;
  font-family: Open Sans !important;
}

/* .topic-allicons {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  margin-left: 7%;
  margin-right: 0.5%;
  background-color: transparent;
}

.topic-each-icon {
  width: 45px;
} */
/* .topic-allicons {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 6px;
  margin-left: 7%;
  margin-right: 0.5%;
  background-color: transparent;
} */
.topic-allicons {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 12px;
  margin-left: 0%;
  margin-right: 0%;
  background-color: transparent;
}

.games-Text {
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: bold;
}

.topics {
  font-family: Open Sans;
  font-size: 12px;
}

.topic-each-icon {
  margin-top: 5px;
  width: 60px;
}

.topic-each-icon-logis {
  width: 40px;
  margin-top: 6px;
}

.topic-each-icon-1 {
  width: 30px;
}

.topic-each-icon-2 {
  width: 35px;
}

.offerings {
  text-align: center;
  color: #31197C;
  font-size: 25px;
  font-weight: 600;
  font-family: Open Sans;
}

.features-vh {
  width: 100%;
  height: 100%;
}

.offerings-allicons {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 5rem;
  margin: 1rem auto;
  background-color: transparent;
  align-items: center;
  justify-content: center;
}

.offerings-allicons-four {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 4.5rem;
  margin: 1rem auto;
  background-color: transparent;
  align-items: center;
  justify-content: center;
}

.flip-lr {
  margin-left: 15%;
  margin-right: 15%;
}

.flip-lr-2 {
  margin-left: 20%;
  margin-right: 20%;
}

.know-more {
  background-color: #31197C !important;
  width: 7rem;
  height: 30px;
  border-color: #31197C !important;
  border-radius: 6px;
  text-align: center;
  margin: auto;
  padding-top: 4px;
  color: white;
  font-size: small;
  font-family: Open Sans !important;
}

.know-more:hover {
  background-color: #31197C;
  color: white;
  border-color: #31197C;
}

.knowmore-left {
  margin-left: 8.5%;
}



.pricing-ml {
  margin-left: 0px;
  margin-right: 0px;
}

.ml-p {
  margin-left: 30px;
  margin-right: 5px;
}

.stats {
  margin-left: 64px;
}

.offerings-allicons-two {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 4.5rem;
  margin: 1rem auto;
  background-color: transparent;
  align-items: center;
  justify-content: center;
}

.offer-each-icon {
  width: 150px;
  margin-left: 0%;
}

.offer-text {
  font-size: 16px;
  font-weight: 500;
  font-family: Open Sans;
}

.team-bg {
  background-color: #31197C;
  height: 100%;
  color: white;
}

.team-heading {
  text-align: center;
  color: white;
  font-size: 23px;
  font-weight: 600;
  font-family: Open Sans;
}

.team-allicons {
  width: 98%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 3rem;
  margin: 1rem auto;
  background-color: transparent;
  align-items: center;
  justify-content: center;
}

.partner-allicons {
  width: 98%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 6rem;
  margin: 1rem auto;
  background-color: transparent;
  align-items: center;
  justify-content: center;
}

.team-allicons-three {
  width: 98%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 3rem;
  margin: 0.5rem auto;
  background-color: transparent;
  align-items: center;
  justify-content: center;
}

.team-each-icon {
  width: 75px;
}

.partner-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.partner-ind-icon {
  width: 160px;
  height: 120px;
  border-radius: 50%;
}

.team-role {
  display: column;
  /* margin-left: auto;
  margin-right: auto; */
}

.team-name {
  margin-top: 5px;
  color: #FF942C;
  font-size: 14px;
  font-weight: 500;
  font-family: Open Sans;
}

.designation {
  color: white;
  font-size: 12px;
  font-weight: normal;
  font-family: Open Sans;
}

.contact-img {
  width: 60%;
  height: 100%;
  margin-top: -1.5rem;
  float: right !important;
}

.c-img {
  text-align: right !important;
}

.connect-vh {
  width: 100%;
  height: 100%;
}

.grow-card {
  width: 70%;
  height: 140px;
}

.l-card {
  box-shadow: 0px 1px 15px #999;
  padding: 8px 8px;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  font-family: Open Sans;
}

.connect {
  margin-left: 4rem;
  margin-top: 1rem;
  font-size: 25px;
  color: #31197C;
  font-weight: 600;
  font-family: Open Sans;
}

.connect-text {
  margin-left: 4rem;
  margin-top: 1rem;
  font-size: 35px;
  color: #31197C;
  font-weight: 600;
  font-family: Open Sans;
}

.connect-gmail {
  margin-left: 4rem;
  font-size: 25px;
  font-weight: 600;
  color: #31197C;
  font-family: Open Sans;
}

.sign-b-agree {
  margin-top: 20%;
}

.sign-b_Agree {
  margin-top: 40%;
}

.sign-b_Agree-Popup {
  margin-top: 20%;
}

.admin-color {
  color: #31197C;
}

.admin-color:hover {
  color: #31197C;
}

.l-app-background {
  width: 100%;
  height: auto;
}

.l-app-border {
  position: absolute;
  left: 10%;
  right: 10%;
  align-items: center;
  justify-content: center;
  margin-top: -26%;
  border: 2px solid white;
  border-radius: 15px;
  width: 80%;
  height: 38%;
}

.powered-by {
  position: relative;
  margin-top: -7rem;
  left: 88%;
  width: 10%;
}

.l-powered-by-nebutech {
  position: relative;
  margin-top: 4rem;
  left: 1%;
  width: 13%;
}

.l-sceem-logo-download {
  width: 8%;
  margin-top: 0.6rem;
  margin-left: 0.6rem;
}

.l-download-text {
  margin-top: -9.5rem;
  font-size: 25px;
  color: white;
  font-weight: 600;
  font-family: Open Sans;
}

.l-download-text-sm {
  margin-top: 0.5rem;
  font-size: 15px;
  color: white;
  font-weight: 400;
  font-family: Open Sans;
}

.appstore {
  width: 15%;
}

.l-googleplay {
  width: 20%;
}

.footer_bg {
  position: relative;
  bottom: 0px;
  width: 100%;
  height: 44%;
  background-color: #282828
}

.sceem-logo {
  width: 18%;
  margin-top: 1.2rem;
  margin-left: 4rem;
}

.iso {
  width: 13%;
  margin-top: -15px;
  margin-left: 4.7rem;
}

.links {
  margin-top: 1.5rem;
  color: white;
  font-size: 15px;
  font-weight: 700;
  margin-left: 0.8rem;
  font-family: Open Sans !important;
}

.links-sm {
  color: white !important;
  font-size: 14px;
  font-weight: 400;
  margin-left: 0.8rem;
  font-family: Open Sans;
  text-decoration: none !important;
}

.copyright {
  color: white !important;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-top: -4px;
  font-family: Open Sans !important;
  text-decoration: none !important;
}

.service::before {
  content: '';
  display: block;
  height: 70px;
  margin-top: -70px;
  visibility: hidden;
}

.service-commit::before {
  content: '';
  display: block;
  height: 30px;
  margin-top: -30px;
  visibility: hidden;
}

.service-team::before {
  content: '';
  display: block;
  height: 40px;
  margin-top: -40px;
  visibility: hidden;
}

.service-home::before {
  content: '';
  display: inline;
  height: 70px;
  margin-top: -70px;
  visibility: hidden;
}

.service-about::before {
  content: '';
  display: block;
  height: 30px;
  margin-top: -30px;
  visibility: hidden;
}

.service-offerings::before {
  content: '';
  display: block;
  height: 100px;
  margin-top: -100px;
  visibility: hidden;
}
.service-more::before {
  content: '';
  display: block;
  height: 30px;
  margin-top: -30px;
  visibility: hidden;
}
.service_signup::before {
  content: '';
  display: block;
  height: 70px;
  margin-top: -70px;
  visibility: hidden;
}
.service-know::before {
  content: '';
  display: block;
  height: 70px;
  margin-top: -70px;
  visibility: hidden;
}
/* @media screen and (min-width: 640px) and (max-width: 840px) {
  .l-page-top {
    position: absolute;
    top: 25%;
    color: #31197C;
    z-index: 1;
    right: 1rem;
    font-size: 5vw;
    font-weight: 600;
    font-family: Open Sans;
  }

  .l-page-text {
    position: absolute;
    top: 29.8%;
    z-index: 1;
    right: 1rem;
    color: grey;
    left: 35%;
    text-align: right;
    font-size: 13px;
    font-weight: normal;
    font-family: Open Sans;
  }
} */
.flip-box {
  background-color: transparent;
  width: 160px;
  height: 160px;
  perspective: 1000px;
  border-radius: 50%;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(-180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #fff;
  color: black;
  width: 160px;
  height: 160px;
}

.offer-back-heading {
  font-size: 14px;
}

.flip-box-back {
  background-color: white;
  color: black;
  border: 4px solid #FF942C;
  font-size: 12px;
  font-family: Open Sans;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  transform: rotateY(180deg);
}

.chain-text {
  font-size: 18px;
  color: #31197C;
  font-weight: 600;
}

.network {
  margin-top: 38% !important;
}

.opportunity {
  margin-top: 38% !important;
}

.advertisement {
  margin-top: 34% !important;
}

.articles {
  margin-top: 38% !important;
}

.polls {
  margin-top: 38% !important;
}

.polls-adv {
  margin-top: 38% !important;
}

.signup-text {
  font-family: Open Sans !important;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #2B2C43;
  margin-left: 3%;
}

.bottom-icon {
  width: 80%;
  position: absolute;
  bottom: 20px;
  right: 0;
}

@media screen and (min-width: 640px) and (max-width: 840px) {
  .anonymous {
    font-size: 17px;
    font-family: Open Sans;
  }

  .article-ratings-text {
    font-size: 16px;
    font-family: Open Sans;
    color: #31197C;
    font-weight: 600;
  }

  .latest-ratings {
    font-size: 18px;
    font-family: Open Sans;
    color: #31197C;
    font-weight: 600;
  }

  .latest-ratings-text-sm {
    font-size: 17px;
    font-family: Open Sans;
    color: #31197C;
    font-weight: 600;
  }

  .feddback_card {
    width: 100%;
  }

  .feddback_card-art {
    width: 100%;
  }

  .feed-ratings {
    padding-left: 10%;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-family: Open Sans;
  }

  .feed-ratings-art {
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-family: Open Sans;
  }

  .rating-v-align {
    margin-top: 4rem !important;
  }

  .rating-v-align-art {
    margin-top: 2.5rem;
  }

  .avgRatingCount-art {
    position: absolute;
    font-size: 20px;
    font-weight: 600;
    top: 3.5rem;
    left: 20%;
  }

  .avgRatingCount {
    position: absolute;
    font-size: 20px;
    font-weight: 600;
    top: 3.5rem;
    left: 20%;
  }

  .desc {
    font-family: Open Sans;
    font-size: 16px;
    height: 150px
  }

}

@media screen and (max-width:639px) {
  .bottom-icon {
    width: 70px;
    position: absolute;
    bottom: 20px;
    right: 0;
  }

  .network {
    margin-top: 26% !important;
  }

  .opportunity {
    margin-top: 24% !important;
  }

  .advertisement {
    margin-top: 15% !important;
  }

  .articles {
    margin-top: 25% !important;
  }

  .polls {
    margin-top: 25% !important;
  }

  .polls-adv {
    margin-top: 16% !important;
  }

  .chain-text {
    display: flex;
    margin-top: 0px;
    font-size: 15px;
    color: #31197C;
    font-weight: 600;
  }

  .service-team::before {
    content: '';
    display: block;
    height: 30px;
    margin-top: -30px;
    visibility: hidden;
  }

  .service::before {
    content: '';
    display: block;
    height: 20px;
    margin-top: -20px;
    visibility: hidden;
  }

  .service-commit::before {
    content: '';
    display: block;
    height: 80px;
    margin-top: 80px;
    visibility: hidden;
  }

  .service-home::before {
    content: '';
    display: block;
    height: 85px;
    margin-top: 85px;
    visibility: hidden;
  }

  .service-about::before {
    content: '';
    display: block;
    height: 40px;
    margin-top: -40px;
    visibility: hidden;
  }

  .service-offerings::before {
    content: '';
    display: block;
    height: 60px;
    margin-top: -60px;
    visibility: hidden;
  }

  .service_signup::before {
    content: '';
    display: block;
    height: 100px;
    margin-top: 100px;
    visibility: hidden;
  }

  .home-image-lan {
    margin-top: 3.5rem;
    width: 100%;
    height: 25vh;
  }

  .l-page-top {
    position: absolute;
    top: 8%;
    color: #31197C;
    z-index: 1;
    right: 1rem;
    font-size: 5vw;
    font-weight: 600;
    font-family: Open Sans;
  }

  .l-page-text {
    position: absolute;
    top: 12.7%;
    z-index: 1;
    right: 1rem;
    color: grey;
    left: 35%;
    text-align: right;
    font-size: 13px;
    font-weight: normal;
    font-family: Open Sans;
  }

  .about-sceem {
    margin-top: 2rem;
    width: 70%;
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .about-sceem-top {
    margin-top: 0.5rem;
  }

  .about-sceem-heading {
    margin-top: 2rem;
    font-size: 20px;
    font-weight: 600;
    font-family: Open Sans;
    margin-left: 2rem;
    color: #31197C;
  }

  .about-sceem-textsm {
    margin-top: 0.5rem;
    font-size: 14px;
    font-weight: 400;
    font-family: Open Sans;
    margin-left: 2rem;
    color: black;
  }

  .vision-bg {
    background-color: #31197C;
    height: 50%;
    color: white;
  }

  .our-vision {
    position: absolute;
    margin-top: -3px;
    width: 60%;
    background-color: #31197C;
    left: 21%;
    right: 20%;
    text-align: center;
    color: white;
    font-size: 25px;
    font-weight: 600;
    font-family: Open Sans;
  }

  .our-topics {
    position: absolute;
    margin-top: -3px;
    width: 56%;
    background-color: #31197C;
    left: 22%;
    right: 20%;
    text-align: center;
    color: white;
    font-size: 25px;
    font-weight: 600;
    font-family: Open Sans;
  }

  .sceem-4es {
    width: 100%;
    height: 100%;
  }

  .vision {
    border: 2px solid #FF942C;
    border-radius: 20px;
    padding: 1.5rem;
    width: 80%;
    margin: 1rem auto;
  }

  /* .topic-allicons {
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-gap: 5px;
    background-color: transparent;
    margin-left: 0%;
    margin-right: 0.8%;
  } */
  .topic-allicons {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 2px;
    background-color: transparent;
    margin-left: 0%;
    margin-right: 0%;
  }

  .games-Text {
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: bold;
  }

  .topic-each-icon {
    margin-top: 5px;
    width: 40px;
  }

  .topic-each-icon-logis {
    width: 30px;
    margin-top: 6px;
  }

  .topic-each-icon-1 {
    width: 20px;
  }

  .topic-each-icon-2 {
    width: 25px;
  }

  .offerings {
    text-align: center;
    color: #31197C;
    font-size: 25px;
    font-weight: 600;
    font-family: Open Sans;
  }

  .features-vh {
    width: 100%;
    height: 100%;
  }

  .offerings-allicons {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 15px;
    padding-left: 0.1rem;
    background-color: transparent;
  }

  .offerings-allicons {
    width: 60%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 1.2rem;
    margin: 0.8rem auto;
    background-color: transparent;
    align-items: center;
    justify-content: center;
  }

  .offerings-allicons-four {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 0rem;
    margin: 0.5rem auto;
    background-color: transparent;
    align-items: center;
    justify-content: center;
  }

  .offerings-allicons-two {
    width: 70%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 2rem;
    margin: 1rem auto;
    background-color: transparent;
    align-items: center;
    justify-content: center;
  }

  .offer-text {
    font-size: 10px;
    font-weight: 500;
    font-family: Open Sans;
  }

  .offer-each-icon {
    width: 55px;
    margin: auto;
    display: inline;
    margin-left: 10px;
  }

  .team-bg {
    background-color: #31197C;
    height: 50%;
    color: white;
  }

  .team-heading {
    text-align: center;
    color: white;
    font-size: 25px;
    font-weight: 600;
    font-family: Open Sans;
  }

  .team-allicons {
    width: 96%;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 0.75rem;
    margin: 1rem auto;
    background-color: transparent;
    align-items: center;
    justify-content: center;
  }

  .partner-allicons {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 0.6rem;
    margin: 1rem auto;
    background-color: transparent;
    align-items: center;
    justify-content: center;
  }

  .team-allicons-three {
    width: 96%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 0.8rem;
    margin: 1rem auto;
    background-color: transparent;
    align-items: center;
    justify-content: center;
  }

  .team-each-icon {
    width: 75px;
  }

  .partner-icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  .team-each-icon-new {
    margin-top: -10px;
    width: 75px;
  }

  .team-role {
    /* display: column;
    margin-left: auto;
    margin-right: auto; */
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .team-name {
    margin-top: 3px;
    color: #FF942C;
    font-size: 13px;
    font-weight: 500;
    font-family: Open Sans;
  }

  .designation {
    color: white;
    font-size: 11px;
    font-weight: normal;
    font-family: Open Sans;
  }

  .connect-vh {
    width: 100%;
    height: 100%;
  }

  .contact-img {
    width: 100%;
    height: auto;
    margin-top: 0;
    float: right !important;
  }

  .connect {
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 18px;
    color: #31197C;
    font-weight: 600;
    font-family: Open Sans;
  }

  .connect-text {
    margin-left: 1rem;
    margin-top: 0.3rem;
    font-size: 22px;
    color: #31197C;
    font-weight: 600;
    font-family: Open Sans;
  }

  .pricing-ml {
    margin-left: 4.5%;
    margin-right: 4.5%;
  }

  .ml-p {
    margin-left: 15px;
    margin-right: 15px;
  }

  .stats {
    margin-left: 0px;
  }

  .connect-gmail {
    margin-left: 1rem;
    font-size: 18px;
    color: #31197C;
    font-weight: 600;
    font-family: Open Sans;
  }

  .l-app-background {
    width: 100%;
    height: auto;
  }

  .l-app-border {
    position: absolute;
    left: 10%;
    right: 10%;
    align-items: center;
    justify-content: center;
    margin-top: -31.5%;
    border: 2px solid white;
    border-radius: 15px;
    width: 80%;
    height: 15.5%;
  }

  .appstore {
    width: 18%;
  }

  .footer_bg {
    width: 100%;
    height: 50%;
    background-color: #282828
  }

  .sceem-logo {
    width: 25%;
    margin-top: 1rem;
    margin-left: 1.5rem;
  }

  .iso {
    width: 20%;
    margin-top: -6px;
    margin-left: 1.7rem;
  }

  .links {
    margin-top: 0.5rem;
    margin-left: 1.5rem;
    color: white;
    font-size: 15px;
    font-weight: 700;
    font-family: Open Sans !important;
  }

  .links-sm {
    color: white;
    margin-left: 1.5rem;
    font-size: 14px;
    font-weight: 400;
    font-family: Open Sans;
    text-decoration: none !important;
  }

  .copyright {
    color: white;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    font-family: Open Sans !important;
    text-decoration: none !important;
  }

  .powered-by {
    position: relative;
    margin-top: -2.8rem;
    left: 86%;
    width: 12%;
  }

  .l-download-text {
    margin-top: -5.4rem;
    font-size: 15px;
    color: white;
    font-weight: 600;
    font-family: Open Sans;
  }

  .l-download-text-sm {
    margin-top: 0.2rem;
    font-size: 12px;
    color: white;
    font-weight: 400;
    font-family: Open Sans;
  }

  .l-googleplay {
    width: 23%;
  }

  .l-powered-by-nebutech {
    position: relative;
    margin-top: 2.5rem;
    left: 2%;
    width: 18%;
  }

  .l-sceem-logo-download {
    width: 10%;
    margin-top: 0.4rem;
    margin-left: 0.4rem;
  }

  .background-lp {
    width: 100%;
    height: 26%;
    font-family: Open Sans;
  }

  .app-border-lp {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 0.5rem;
    margin: auto;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    border: 1.5px solid #31197C;
    border-radius: 10px;
    height: 100%;
  }

  .sceem-logo-download-lp {
    width: 80%;
    margin-left: 4%;
    /* margin-top: 0.4rem;
    margin-left: 0.4rem; */
  }

  .powered-by-nebutech-lp {
    /* margin-top: 0.5rem;
    width: 18%;
    margin-left: 80%;
    margin-bottom: 6px; */
    width: 85%;
    margin-right: 10px;
  }

  .download-text-lp {
    margin-top: -0.5rem;
    font-size: 10px;
    color: black;
    font-weight: 400;
    font-family: Open Sans !important;
  }

  .googleplay-lp {
    margin-top: 3px;
    width: 23%;
  }

  .flip-box {
    background-color: transparent;
    width: 80px;
    height: 80px;
    perspective: 1000px;
    border-radius: 50%;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateY(-180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-box-front {
    background-color: #fff;
    color: black;
    width: 80px;
    height: 80px;
  }

  .offer-back-heading {
    font-size: 11px;
  }

  .flip-box-back {
    background-color: white;
    color: black;
    border: 3px solid #FF942C;
    font-size: 9px;
    font-family: Open Sans;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    transform: rotateY(180deg);
  }

  .sign-b-agree {
    margin-top: 1rem;
  }

  .sign-b_Agree {
    margin-top: 3rem;
  }

  .sign-b_Agree-Popup {
    margin-top: 2rem;
  }

  /* .enable-center {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    width: 100%;
    border: none;
    min-height: 100%;
  } */

  .signup-text {
    font-family: Open Sans !important;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #2B2C43;
    margin-left: 8.5%;
    /* margin-top: 5rem; */
  }

  .knowmore-left {
    margin-left: 8%;
  }

  .know-more-lr {
    align-items: left;
    margin-left: 1.2rem;
    margin-right: 0.5rem;
    font-family: Open Sans !important;
  }

  .rat-bg {
    position: absolute;
    left: 30%;
  }

  .article-ratings-text {
    font-size: 16px;
    font-family: Open Sans;
    color: #31197C;
    font-weight: 600;
  }

  .listen-T {
    margin-top: 0px;
  }

  .latest-ratings {
    font-size: 18px;
    font-family: Open Sans;
    color: #31197C;
    font-weight: 600;
  }

  .latest-ratings-text-sm {
    font-size: 17px;
    font-family: Open Sans;
    color: #31197C;
    font-weight: 600;
  }

  .feddback_card {
    width: 100%;
  }

  .feddback_card-art {
    width: 100%;
    margin-left: 10px;
  }

  .feed-ratings {
    padding-left: 10%;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-family: Open Sans;
  }

  .feed-ratings-art {
    padding-left: 7%;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-family: Open Sans;
  }

  .rating-v-align {
    margin-top: 2.2rem;
  }

  .rating-v-align-art {
    margin-top: 1.1rem;
  }

  .avgRatingCount-art {
    position: absolute;
    font-size: 20px;
    font-weight: 600;
    top: 1.5rem;
    left: 25%;
  }

  .avgRatingCount {
    position: absolute;
    font-size: 20px;
    font-weight: 600;
    top: 2.5rem;
    left: 27%;
  }

  .desc {
    font-family: Open Sans;
    font-size: 16px;
    height: 100px
  }

  .flip-lr {
    margin: auto;
  }

  .flip-lr-2 {
    margin: auto
  }

  .poll-bg {
    background-color: #31197C;
    height: calc(100vh);
    overflow-y: auto;
  }

  .poll-bg-pr {
    background-color: #31197C;
    height: calc(75vh);
    overflow-y: auto;
  }

  .topblue_bar {
    z-index: 1;
    overflow: hidden;
    background-color: #31197C;
    position: fixed;
    top: -5px;
    width: 100%;
    height: 70px;
  }

  .enable-poll-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    border: none;
    min-height: calc(85vh);
  }

  .answers-card {
    margin-top: 6vh;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .expired-card {
    margin-top: -4vh;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .topic-top {
    margin-top: 2vh;
  }

  .topic-name {
    font-family: Open Sans !important;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    padding: 0 1.5rem;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.8px;
    color: #FF942C;
  }

  .poll-result-card {
    padding: 0.5rem 1.1rem 1rem 0.5rem;
    height: auto;
    width: 100%;
    background-color: white;
    border-radius: 20px;
    margin: 0 auto;
  }

  .poll-answer-card {
    padding: 0.5rem 0.5rem 1rem 0.7rem;
    height: auto;
    width: 94%;
    background-color: white;
    border-radius: 20px;
    margin: 0 auto;
  }

  .read-also {
    font-size: 16px;
    font-family: Open Sans !important;
    color: #311942;
    font-weight: 600;
    border: 3px solid #ff942c;
    padding: 5px 10px;
    border-radius: 20px;
  }

  .latest-art {
    font-size: 17px;
    font-weight: 600;
    color: #FF942C;
    margin-left: 1.3rem;
    margin-bottom: 15px;
  }
  .favbits-author {
    margin-top: 4px;
    width: 42px;
    margin-bottom: 4px;
}
/* .latest-art-card {
  width: 98%;
  margin-left:1.2rem;
  margin-right: 1.2rem;
  margin-bottom: 10px;
  background: #fffffb;
  border-radius: 7px;
  border: none;
  box-shadow: 2px 2px 6px #bebebe,
      -2px -2px 6px #ffffff;
} */
.latest-art-card {
  height: 4.5rem;
  width: 98%;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  background: #fffffb;
  border-radius: 7px;
  border: none;
  box-shadow: 2px 2px 6px #bebebe, -2px -2px 6px #ffffff;
}
.latest-art-card .row {
  height: 100%;
}
.favbits-title-latest {
  font-size: 15px;
  color: black;
  text-align: left;
}
  .poll-result-card-post {
    padding: 0.5rem 1.1rem 1rem 0;
    height: auto;
    background: #ebfcfc;
    border-radius: 20px;
    margin: 0 0.5rem;
  }

  .quiz-card-border {
    width: 100% !important;
    border: 1px solid lightgray;
    background-color: #fff2e6;
    border-radius: 12px;
    padding-bottom: 2px;
    padding-top: 2px;
    margin-left: 4%;
  }

  .q-submit-btn {
    width: 4.5rem;
    height: 32px;
    color: white !important;
    padding-top: -5px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    font-family: Open Sans !important;
    background: #31197C !important;
  }

  .q-submit-btn:hover {
    color: white !important;
    background: #31197C !important;
  }

  .poll-answer-card-post {
    padding: 0.5rem 0.5rem 1rem 0.7rem;
    height: auto;
    background: #ebfcfc;
    /* background-color: #fff2e6; */
    border-radius: 20px;
    margin: 0 0.6rem;
  }

  .question_post {
    font-weight: bold;
    font-family:Raleway, sans-serif;
    font-size: 18px;
  }

  .poll-question {
    padding: 0.2rem;
    margin-top: 0.2rem;
    font-family: Open Sans !important;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 28px;
    text-align: left;
    letter-spacing: 0.8px;
    color: #000000;
  }

  .poll-question-r {
    padding: 0.2rem;
    margin-left: 0.7rem;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
    font-family: Open Sans !important;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 28px;
    text-align: left;
    letter-spacing: 0.8px;
    color: #000000;
  }

  .poll-question-post {
    /* padding: 0.2rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 0.2rem;
    font-family: Open Sans !important;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0.8px;
    color: #000000; */
    padding: 0.2rem;
    margin-top: 0.2rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.8px;
    color: #000000;
  }

  .polloptions-card {
    margin-top: 1rem;
    width: 98%;
    height: 70px;
    display: flex;
    padding: 10px 6px 10px 6px;
    border-radius: 10px;
    border: 1px solid lightgrey;
  }

  .polloptions-card-post {
    margin-top: 1rem;
    width: 98%;
    height: 50px;
    display: flex;
    padding: 2px 5px;
    border-radius: 10px;
    border: 1px solid lightgrey;
  }

  .selected {
    /* background: #FFE6CE !important; */
    border: 1px solid #DB6C00;
  }

  .poll-text {
    font-size: 15px;
    font-family: Open Sans !important;
    align-items: flex-start;
    float: left;
    font-weight: 600;
    justify-content: left;
    margin-top: auto;
    margin-bottom: auto;
    animation-duration: 3s;
    animation-name: slidein;
  }

  .poll-submit-btn {
    width: 8rem;
    height: 50px;
    color: black;
    border-radius: 6px;
    font-family: Open Sans !important;
    background: white !important;
  }

  .poll-submit-btn:hover {
    color: black;
    background: white !important;
  }

  .login-logo {
    position: fixed;
    width: 145px;
    height: 45px;
    top: 10px;
    left: 19px;
  }

  .goback-poll-btn {
    width: 10rem;
    height: 50px;
    color: black;
    border-radius: 6px;
    font-family: Open Sans !important;
    background: white !important;
  }

  .goback-poll-btn:hover {
    color: black;
    background: white !important;
  }

  .app-border-top-pr {
    margin-left: 6%;
    margin-right: 8%;
    align-items: center;
    justify-content: center;
    margin-top: -3.5%;
    border: 1.5px solid #31197C;
    border-radius: 15px;
    width: 96%;
    height: 40%;
  }

  .sceem-logo-download-new-pr {
    width: 25%;
    margin-top: 0.6rem;
    margin-left: 0.6rem;
  }

  .powered-by-nebutech-new-pr {
    margin-top: 0.7rem;
    margin-left: 74%;
    margin-bottom: 5px;
    width: 25%;
  }

  .article-chain-text-pr {
    margin-top: 4%;
    font-size: 14px;
    color: #31197C;
    font-family: Open Sans !important;
    font-weight: 600;
  }

  .box-text-lp-pr {
    margin-top: 0px;
    font-size: 14px;
    color: #31197C;
    font-family: Open Sans !important;
    font-weight: 600;
  }

  .campaign-name {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
  }

  .adv-sm {
    margin-top: 1rem;
    margin-left: 0;
    margin-right: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.8px;
    color: #000000;
  }

  .campaign-url {
    margin-left: 0;
    margin-right: 0;
    font-size: 20px;
    font-weight: 600;
    color: #31197C;
    cursor: pointer;
  }

  .opp-heading {
    margin-left: -5px;
  }
}