.ml-Text {
    margin-left: 3rem;
    margin-right: 2rem;
}

.your-jitbits {
    font-size: 20px;
    font-weight: 600;
    color: #FF942C;
    margin-left: 20px;
    margin-bottom: 15px;
    font-family: Open Sans !important;
}
.your-jitbits-order {
    font-size: 20px;
    font-weight: 600;
    color: #FF942C;
    margin-left: 20px;
    margin-bottom: 10px;
    font-family: Open Sans !important;
}
.profile-Auth-img {
    margin-top: -10px;
    width: 140px;
}

.user-Name {
    font-family: Open Sans !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: black;
}

.author-text {
    font-family: Open Sans !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54)
}

.rewards-earned {
    font-family: Open Sans !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: black;
}

.j-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin: 1px 15px;
}
.profile-text-top{
    margin-top: 3.5px;
}
.profile_Card_ {
    width: 95%;
    border-radius: 5px;
    margin: 15px 10px 15px 10px;
    height: auto;
    padding: 8px 10px;
    border: none;
    box-shadow: 1px 1px 3px #a8a8a8,
        -2px -2px 4px #ffffff;
    display: flex;
    font-family: Open Sans !important;
    font-size:14px;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}
.profile_Card_width {
    width: 95%;
}
.profile_Card_width_sme {
    width: 94%;
}
.profile_Card_sme {
    width: 95%;
    border-radius: 5px;
    margin: 15px 10px 12px 10px;
    height: auto;
    padding: 5px;
    border: none;
    box-shadow: 1px 1px 3px #a8a8a8,
        -2px -2px 4px #ffffff;
    display: flex;
    font-family: Open Sans !important;
    font-size:10px;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}
.profile-border {
    border: 1px solid #31197C;
    border-radius: 10px;
    min-height: 54vh;
}

.self-text {
    font-size: 18px;
    font-family: Open Sans !important;
    color: #31197C;
    font-weight: 600;
    margin-left: 20px;
}

.app-border-top-profile {
    margin-left: 4%;
    margin-right: 4%;
    align-items: center;
    justify-content: center;
    border: 1.5px solid #31197C;
    border-radius: 15px;
    width: 92%;
    height: 100%;
}

.nebutech-image {
    position: absolute;
    left: 1rem;
    bottom: -10px;
    width: 25%;
}

.smeDesc {
    width: 100%;
    overflow-y: auto;
    height: 6.5rem;
    font-size:12px;
    font-family: Open Sans !important;
    margin:2px;
}

@media screen and (max-width:639px) {
    .smeDesc {
        width: 100%;
        overflow-y: auto;
        height: 5.5rem;
        font-size:12px;
        font-family: Open Sans !important;
        margin:2px;
    }
    .nebutech-image {
        position: absolute;
        left: 1rem;
        bottom: -12rem;
        width: 25%;
    }

    .app-border-top-profile {
        margin-left: 5%;
        margin-right: 9.5%;
        align-items: center;
        justify-content: center;
        margin-top: -3.5%;
        border: 1.5px solid #31197C;
        border-radius: 15px;
        width: 90.5%;
        height: 40%;
    }

    .self-text {
        font-size: 18px;
        font-family: Open Sans !important;
        color: #31197C;
        font-weight: 600;
        margin-left: 20px;
    }

    .profile-border {
        border: 1px solid #31197C;
        border-radius: 10px;
        min-height: 50vh;
    }

    .ml-Text {
        margin-left: 0.3rem;
        margin-right: 0rem;
    }

    .your-jitbits {
        font-size: 20px;
        font-weight: 600;
        color: #FF942C;
        margin-left: 20px;
        margin-bottom: 15px;
        font-family: Open Sans !important;
    }
    .your-jitbits-order {
        font-size: 20px;
        font-weight: 600;
        color: #FF942C;
        margin-left: 20px;
        margin-bottom: 8px;
        font-family: Open Sans !important;
    }
    .profile-Auth-img {
        margin-top: -10px;
        width: 140px;
    }

    .user-Name {
        font-family: Open Sans !important;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: black;
    }

    .author-text {
        font-family: Open Sans !important;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: rgba(0, 0, 0, 0.54)
    }

    .rewards-earned {
        font-family: Open Sans !important;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: black;
    }

    .j-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        margin: 1px 15px;
    }

    .profile_Card_ {
        width: 93%;
        font-size:14px;
        border-radius: 5px;
        margin: 15px 10px 12px 10px;
        height: auto;
        font-family: Open Sans !important;
        padding: 8px 10px;
        border: none;
        box-shadow: 1px 1px 3px #a8a8a8,
            -2px -2px 4px #ffffff;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-between;
    }
    .profile_Card_width {
        width: 93%;
    }
    .profile_Card_width_sme {
        width: 93%;
    }
    .profile_Card_sme {
        width: 93%;
        font-size:13px;
        border-radius: 5px;
        margin: 15px 10px 12px 10px;
        height: auto;
        font-family: Open Sans !important;
        padding: 5px;
        border: none;
        box-shadow: 1px 1px 3px #a8a8a8,
            -2px -2px 4px #ffffff;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-between;
    }
}