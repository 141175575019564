.terms-heading {
    margin-top: 5rem !important;
    margin-left: 1.6rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.8px;
    color: #FF942C;
}

.terms-color {
    color: #31197C;
}

.terms-heading-about {
    margin-top: 3rem;
    margin-left: 1.6rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.8px;
    color: #1E2022;
}

.terms-sm {
    margin-top: 1rem;
    margin-left: 1.6rem;
    margin-right: 1rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.8px;
    color: #000000;
}

.terms_Text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
    font-family: Open Sans;
    font-style: normal;
}

.back_arrow {
    top: 10.2%;
    margin-left: 1.6rem;
    width: 10%;
    position: fixed;
}

.backarrow {
    margin-top: 25%;
    margin-left: 1.6rem;
    width: 10%;
}

.heading {
    font-size: 20px;
    font-family: Open Sans;
}

.circle-dp {
    height: 8px;
    width: 8px;
    background-color: grey;
    border-radius: 50%;
    font-family: Open Sans !important;
    display: inline-block;
    margin-left: 20px;
    margin-right: 10px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle-new {
    position: relative;
    float: right;
    top: 110px;
    width: 40px;
    height: 60px;
}

.circle-yellow-new {
    position: relative;
    float: left;
    top: 180px;
    width: 25px;
    height: 60px;
}

.circle-blue-new {
    position: relative;
    top: 410px;
    left: -27px;
    width: 25px;
    height: 60px;
}

.circle-reverse-yellow-new {
    position: relative;
    float: right;
    top: 525px;
    width: 25px;
    height: 60px;
    right: -40px;
}

.square-new {
    position: absolute;
    top: 80px;
    right: 0;
    width: 50px;
    height: 50px;
}

/* .write-articles-count {
    border-radius: 10px;
    margin-left: 20px;
    height: 80px;
    width: 60%;
    padding: 14px 5px 5px 5px;
    border: none;
    margin-top: -10px;
    background: #f7f7f5;
}

.likes-count {
    border-radius: 10px;
    height: 80px;
    position: absolute;
    top: 142px;
    right: 15px;
    height: 80px;
    width: 25%;
    padding: 15px 5px 10px 10px;
    border: none;
    background: #f7f7f5;
}

.articles-count {
    border-radius: 10px;
    height: 175px;
    position: absolute;
    top: 280px;
    left: 45px;
    width: 75%;
    padding: 12px 5px 5px 5px;
    border: none;
    background: #f7f7f5;
}

.polls-count {
    border-radius: 10px;
    right: 40px;
    height: 80px;
    position: absolute;
    top: 480px;
    width: 30%;
    padding: 15px 5px 10px 5px;
    border: none;
    background: #f7f7f5;
}

.article-read-count {
    border-radius: 10px;
    left: 55px;
    height: 80px;
    position: absolute;
    top: 177px;
    width: 45%;
    padding: 15px 5px 10px 5px;
    border: none;
    background: #f7f7f5;
}

.reach-count {
    border-radius: 10px;
    height: auto;
    position: absolute;
    top: 575px;
    left: 20px;
    width: 90%;
    padding: 12px 5px 5px 5px;
    border: none;
    background: #f7f7f5;
}

.campaigns-count {
    border-radius: 10px;
    height: 80px;
    position: absolute;
    top: 475px;
    left: 40px;
    width: 40%;
    padding: 12px 5px 5px 5px;
    border: none;
    background: #f7f7f5;
} */


.new-cir {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fcb269;
    margin-top: -7px;
}

.new-cir1 {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #f6f871;
    margin-top: -7px;
}

.new-cir2 {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #7bfa9b;
    margin-top: -7px;
}

.new-cir3 {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #d7f8b1;
    margin-top: -7px;
}

.new-cir4 {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #9de8f5;
    margin-top: -7px;
}

.new-cir5 {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #e0a0af;
    margin-top: -7px;
}

.aboutcolor {
    background-image: linear-gradient(rgb(232, 229, 243), rgb(234, 240, 243));
    height: 100vh;
}

.neomorphic-shadows {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.neomorphic-shadows-in {
    -webkit-box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
}

/* 
.articles-Published {
    border-radius: 10px;
    height: 125px;
    width: 29.4vw;
    padding: 12px 0 5px 0;
    border: 2px solid rgb(228, 225, 225);
}

.articles-graph {
    border-radius: 10px;
    height: auto;
    width: 92vw;
    padding: 12px 5px 5px 5px;
    margin-left: 1rem;
    margin-right: 0.8rem;
    border: 2px solid rgb(228, 225, 225);
}

.campaigns-polls {
    border-radius: 10px;
    height: 80px;
    width: 29.4vw;
    padding: 12px 0 5px 0;
    border: 2px solid rgb(228, 225, 225);
}

.questions- {
    border-radius: 10px;
    height: 105px;
    width: 32vw;
    padding: 12px 0 5px 0;
    border: 2px solid rgb(228, 225, 225);
}

.questions-authors {
    border-radius: 10px;
    height: 105px;
    width: 58vw;
    padding: 12px 0 5px 0;
    border: 2px solid rgb(228, 225, 225);
}

.eminent-authors {
    border-radius: 10px;
    height: auto;
    width: 92vw;
    padding: 12px 5px 5px 5px;
    margin-left: 1rem;
    margin-right: 0.8rem;
    border: 2px solid rgb(228, 225, 225);
} */

/* 
  .terms-heading{
    margin-top:7rem;
    margin-left: 1.6rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.8px;
    color: #1E2022;
    }
    .terms-heading-about{
        margin-top:4rem;
        margin-left: 1.6rem;
        font-family: Noto Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        letter-spacing: 0.8px;
        color: #1E2022;
        }
    .terms-sm{
    margin-top:1rem;
    margin-left: 1.6rem;
    margin-right: 1rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.8px;
    color: #000000;
    }
    .terms_Text{
    font-size: 15px;
    line-height: 25px;
    margin-top: 5px;
    font-family: Noto Sans;
    font-style: normal;
    }
    .back_arrow{
        top: 10.2%;
        margin-left:1.6rem;  
        width:10%; 
        position:fixed;
        }
    .backarrow{
        margin-top: 25%;
        margin-left:1.6rem;  
        width:10%; 
        }
    .heading{
    font-size:20px;
    }
    .circle-dp{
        height: 8px;
        width: 8px;
        background-color: grey;
        border-radius: 50%;
        display: inline-block;
        margin-left:20px;
        margin-right:10px;
       } 
       .center {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .circle-new {
        position: relative;
        float: right;
        top: 110px;
        width: 40px;
        height: 60px;
      }
      
      .circle-yellow-new {
        position: relative;
        float: left;
        top: 180px;
        width: 25px;
        height: 60px;
      }
      
      .circle-blue-new {
        position: relative;
        top: 410px;
        left: -27px;
        width: 25px;
        height: 60px;
      }
      .circle-reverse-yellow-new {
        position: relative;
        float: right;
        top: 500px;
        width: 25px;
        height: 60px;
        right: -40px;
      }
      .square-new {
        position: absolute;
        top: 90px;
        right: 20px;
        width: 50px;
        height: 50px;
      }
      .likes-count{
        border-radius: 10px;
        margin-left:20px;
        height: auto;
        width:30%;
        padding:12px 5px 5px 5px;
        border:none;
        background: #f7f7f5;
    }
      .campaigns-count{
          border-radius: 10px;
          height: 100px;
          position:absolute;
          top:150px; 
          right:20px;
          width:50%;
          padding:12px 5px 5px 5px;
          border:none;
          background: #f7f7f5;
      }
      .articles-count{
        border-radius: 10px;
        height: auto;
        position:absolute;
        top:280px; 
        left:20px;
        width:70%;
        padding:12px 5px 5px 5px;
        border:none;
        background: #f7f7f5;
    }
    .polls-count{
        border-radius: 10px;
        right:20px;
        height: 90px;
        position:absolute;
        top:409px; 
        width:30%;
        padding:15px 5px 10px 5px;
        border:none;
        background: #f7f7f5;
    }
    .reach-count{
        border-radius: 10px;
        height: 100px;
        position:absolute;
        top:520px; 
        left:20px;
        width:84%;
        padding:12px 5px 5px 5px;
        border:none;
        background: #f7f7f5;
    }
    .bold-text{
        font-weight:700;
    }
    .new-cir{
        width:45px;
        height:45px;
        border-radius: 50%;
        background-color: #fcb269;
        margin-top:-7px;
    }
    .new-cir1{
      width:35px;
      height:35px;
      border-radius: 50%;
      background-color: #f6f871;
      margin-top:-7px;
    }
    .new-cir2{
      width:45px;
      height:45px;
      border-radius: 50%;
      background-color: #7bfa9b;
      margin-top:-7px;
    }
    .new-cir3{
      width:35px;
      height:35px;
      border-radius: 50%;
      background-color: #f7adde;
      margin-top:-7px;
    }
    .new-cir4{
      width:55px;
      height:55px;
      border-radius: 50%;
      background-color: #9de8f5;
      margin-top:-9px;
    }
    .aboutcolor{
        background-image: linear-gradient(rgb(232, 229, 243), rgb(234, 240, 243));
        height:100vh;
    }
    .neomorphic-shadows {
      -webkit-box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff;
            box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff;
    }
    .neomorphic-shadows-in{
    -webkit-box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
    } */