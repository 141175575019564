.adv-left-right-gap-d {
    margin-left: 3.5rem;
    margin-right: 2rem;
}

.article-left-right-d {
    margin-left: 4rem;
    margin-right: 3rem;
}

.tshirt-order-img {
    width: 100%;
    height: 100%;
}

.form-fontsize {
    font-size: 12px;
    font-family: Open Sans;
    padding: 2px;
}

.label-order-text {
    font-size: 14px;
    font-family: Open Sans;
}

.gppa {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.upload-trans {
    width: auto;
    height: 40px;
    border-radius: 6px;
    padding: 5px;
    background-color: #31197C;
    color: white;
    font-size: 14px;
    font-family: Open Sans;
}

.upload-trans:hover {
    width: auto;
    height: 40px;
    border-radius: 6px;
    padding: 5px;
    background-color: #31197C;
    color: white;
    font-size: 14px;
    font-family: Open Sans;
}

.cancelbutton,
.deletebutton {
    width: 6rem;
    height: 33px;
    color: #31197C;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid #31197C;
    padding-bottom: 5px;
    padding: 5px;
    border-radius: 10px;
}

.cancelbutton:hover,
.deletebutton:hover {
    width: 6rem;
    height: 33px;
    font-weight: 600;
    font-size: 14px;
    color: white;
    border: 1px solid #31197C;
    background-color: #31197C;
    padding-bottom: 5px;
    padding: 5px;
    border-radius: 10px;
}

.cancelbutton {
    cursor: pointer;
}

.deletebutton {
    cursor: pointer;
}

/* .adv-image-home-detail {
    max-width: 50px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
} */
/* .adv-image-home-detail {
    max-width: 65%;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
} */
.adv-image-home-detail {
    max-width: 70%; /* Allow the image to take full width */
    height: 100%; /* Ensure the image takes the full height of its container */
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 63px;
    object-fit: contain;
}
.adv-detail-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    min-height: 45px;
    padding: 2px;
}

.camp-name-home-detail {
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    color: black;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.camp-text-sm {
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    margin-top: 3px;
    color: gray;
}

.arrow-adv {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    padding: 2px;
    z-index: 1;
    position: relative;
}

.adv-card {
    margin: 2px 1.5rem;
    padding: 2px;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    height:70px;
}

.author-icon-d {
    width: 80px;
    margin-left: 0;
    margin-right: 0
}

.author-desc-d {
    margin-left: 0;
    margin-right: 0
}

.topic-art-d {
    margin-left: 0;
    margin-right: 0
}

.html-text-new-des {
    font-family: Raleway, sans-serif !important;
    font-style: normal;
    text-align: left;
    line-height: 24px;
    font-size: 15px;
    color: #000000;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    margin-top: 0.2rem;
}

.html-text-new-des.p-type {
    max-height: calc(24px * 14);
    /* Limit to 20 lines */
    overflow: hidden;
    position: relative;
}

.html-text-new-des.p-type:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    /* Height of the fade effect */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    /* Fading effect */
}

.html-text-new-des h1 {
    color: #31197C;
}

/* For "G" type, no limit, show content normally */
.html-text-new-des.g-type {
    display: block;
}

.block-d {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

._heading-d {
    font-family: Raleway, sans-serif !important;
    font-style: normal;
    font-weight: 1000;
    font-size: 18px;
    text-align: left;
    line-height: 24px;
    color: #FF942C;
    margin-left: 0.1rem;
    margin-top: 1rem;
}

.article_image-d {
    border-radius: 12px;
    background-color: white;
    padding: 1rem;
    margin-left: 0rem;
    margin-right: 0rem;
}

.Article_Image_width-d {
    width: 60%;
    height: 100%;
    margin-left: 0px;
    border: none;
    border-radius: 0px;
}

.quiz-card-border-d {
    width: 100% !important;
    border: none;
    background-color: #f6f0fa;
    border-radius: 12px;
    padding-bottom: 2px;
    padding-top: 2px;
    margin-left: 0%;
}

.art-poll-text-d {
    font-size: 18px;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    color: #FF942C;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-content {
    font-weight: 600;
    font-family: Open sans;
    color: #ff942c;
    font-size: 16px;
}

.table-of-contents {
    font-family: Raleway, sans-serif !important;
    margin-top: 5.5rem;
}

.table-of-contents ol {
    list-style-type: decimal;
    margin-left: 1.5rem;
    font-weight: 600;
    color: black;
    line-height: 2rem;
    font-size: 1rem !important;
    font-family: Raleway, sans-serif !important;
}

.table-of-contents ul {
    list-style-type: disc;
}

.poll-result-card-post-d {
    padding: 0.5rem 1.2rem 1rem 0;
    height: auto;
    background-color: #ffffff;
    /* background-color:#f6f0fa; */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin: 0;
    width: 103.3%;
    border: none;
    font-family: Raleway, sans-serif;
}

.poll-answer-card-post-d {
    padding: 0.5rem 0.5rem 1rem 1.8rem;
    height: auto;
    background-color: #ffffff;
    /* background: #f6f0fa; */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin: 0;
    border: none;
    width: 103.3%;
    font-family: Raleway, sans-serif;
}

.poll-question-card-post-dd {
    padding: 0.5rem 0.5rem 0.6rem 0.5rem;
    height: auto;
    background: #fcecdd;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0 0 0 0.8rem;
    border: none;
    width: 100%;
}

.polloptions-card-post-d {
    margin-top: 1rem;
    width: 101.5%;
    height: 50px;
    display: flex;
    background: white;
    padding: 2px 5px;
    border-radius: 10px;
    margin-left: -1rem;
    border: 1px solid lightgrey;
}

.selected {
    /* background: #FFE6CE !important; */
    border: 1px solid #DB6C00;
}

.container_class {
    width: 100%;
    height: 7vh;
    background-color: white;
    border-radius: 10px;
    font-family: Open Sans;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid lightgrey;
}

.latest-dd {
    padding: 0.5rem 0.5rem 0.6rem 0.5rem;
    height: auto;
    background: #fcecdd;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0;
    border: none;
    width: 100%;
}

.latest-stats {
    padding: 0.5rem 0.5rem 0.6rem 0.5rem;
    height: auto;
    background: #fcecdd;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0;
    border: none;
    width: 97.5%;
}

.latest-top-gap {
    margin-top: 7rem;
}

.visit-btn-d {
    border: #31197C;
    background: #31197C !important;
    width: auto;
    height: 2.5rem;
    padding: auto;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    color: white;
    display: flex;
    white-space: nowrap;
    font-family: Open Sans;
    text-align: center;
    font-size: 14px;
}

.visit-btn-d:hover {
    border: #31197C;
    background: #31197C !important;
    width: auto;
    height: 2.5rem;
    padding: auto;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    color: white;
    display: flex;
    white-space: nowrap;
    text-align: center;
}

.poll-question-card-post-d {
    padding: 0.5rem 0.5rem 0.6rem 0.5rem;
    height: auto;
    background: #fcecdd;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0;
    width: 103.3%;
    border: none;
    font-family: Raleway, sans-serif;
}

.poll-text-d {
    font-size: 15px;
    font-family: Raleway, sans-serif !important;
    align-items: flex-start;
    font-weight: 500;
    float: left;
    justify-content: left;
    margin-top: auto;
    margin-bottom: auto;
    animation-duration: 3s;
    animation-name: slidein;
}

.latest-art-d {
    font-size: 20px;
    font-weight: 600;
    color: #FF942C;
    font-family: Open Sans;
}

.latest-art-card-d {
    padding: 5px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1px;
    /* box-shadow: 2px 2px 6px #bebebe, -2px -2px 6px #ffffff; */
}

.latest-art-card-d-img {
    height: 100px;
    /* Set your desired height */
    width: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    /* Crop the image if it's too big, maintaining aspect ratio */
}

.card-text-container {
    padding: 10px;
}

.topic-d {
    font-family: Open Sans;
    font-size: 12px;
}

.favbits-author {
    margin-top: 4px;
    width: 35px;
    margin-bottom: 4px;
}

.favbits-title-latest-d {
    font-size: 13px;
    color: black;
    text-align: left;
    font-family: Open Sans;
    font-weight: 600;
}

.articles-Published-d {
    border-radius: 10px;
    height: 135px;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 15px 10px;
    border: none;
    align-items: center;
}

.articles-Published-feddup {
    border-radius: 10px;
    height: 135px;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 15px 6px;
    border: none;
    align-items: center;
}

.feddup-icon-d {
    width: 90%;
    background: #f3f1f2;
    padding: 5px;
    border-radius: 5px;
}

.text-in-about-d {
    font-weight: 600;
    font-size: 18px;
    padding: 8px 5px;
    font-family: Open Sans;
    color: #5819a8;
}

.bold-text-d {
    font-weight: 700;
    margin: 5px;
    color: #FF942C;
    font-size: 25px;
}

.articles-graph-d {
    border-radius: 10px;
    height: auto;
    width: 99%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    padding: 10px;
    border: none;
}

.sceem-logo-download-new-d {
    width: 50%;
    margin-top: 1rem;
    margin-left: 1rem;
}

.member-d {
    font-size: 20px;
    font-family: Open Sans;
    font-weight: 600;
    color: #FF942C;
}

.link-text-d {
    font-size: 20px;
    font-family: Open Sans;
    font-weight: 600;
    color: #FF942C;
}

.powered-by-nebutech-new-d {
    margin-top: 0.8rem;
    width: 28%;
}

.top-articles-per-topic-d {
    font-weight: 600;
    color: black;
    font-size: 22px;
    font-family: Open Sans;
}

.scanner-card-d {
    border-radius: 10px;
    height: 370px;
    width: 99%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    padding: 10px;
    border: none;
}

.scanner-text-d {
    font-weight: 600;
    color: black;
    font-size: 20px;
    font-family: Open Sans;
    padding: 5px;
}

.scanner-text-sm-d {
    font-weight: 600;
    color: black;
    font-size: 16px;
    font-family: Open Sans;
    padding: 5px;
}

.tshirt {
    font-size: 18px;
    font-weight: 600;
    font-family: Open Sans;
}

.tshirt-left {
    padding-left: 0;
}

.tshirt-sm {
    font-size: 14px;
    font-weight: 600;
    font-family: Open Sans;
}

.article-sm-img {
    width: 80%;
}

.tshirt-img {
    width: 99%;
    height: 100%;
    margin-right: 3px;
    margin-left: 0;
}

.tshirt-img1 {
    /* width: 180px;
    height: 170px;
    margin-right: 0;
    margin-left: 0; */
    width: 99%;
    height: 100%;
    margin-right: 0;
    margin-left: 3px;
}

.rating-v-align-ar-d {
    margin-top: 0.2rem;
}

.milestone-img-art-d {
    width: 90%;
}

.avgRatingCount-art-d {
    position: absolute;
    font-size: 22px;
    font-weight: 600;
    top: 0.25rem;
    left: 24%;
}

.avgRatingCount {
    position: absolute;
    font-size: 25px;
    font-weight: 600;
    top: 1.8rem;
    left: 28%;
}

.art-d::before {
    content: '';
    display: block;
    height: 21rem;
    margin-top: -21rem;
    visibility: hidden;
}

.art-d-poll::before {
    content: '';
    display: block;
    height: 7.5rem;
    margin-top: -7.5rem;
    visibility: hidden;
}

.ts-left-right-d {
    margin-left: 0;
    margin-right: 0;
}

/* .bannerImg-d{
    width:180px;
    height:50px;
} */
.bannerImg-d {
    width: 200px;
    max-height: 50px;

}

.table-of-contents-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    background-color: #fcecdd;
    padding: 10px;
    border-radius: 5px;
    font-size: 18px;
    font-family: Raleway, sans-serif !important;
    margin-left: 0;
    margin-right: 0;
}

.stickytable {
    position: sticky;
    top: 4rem;
    z-index: 10;
}

.dd-content {
    font-family: Raleway, sans-serif !important;
    background-color: #ffffff;
    padding: 5px 2px 1px 1px;
    margin-left: 0;
    width: 100%;
    margin-top: -0.1rem;
}

.dd-content ol {
    list-style-type: decimal;
    margin-left: 2rem;
    font-weight: 500;
    color: black;
    font-size: 14px !important;
    font-family: Raleway, sans-serif !important;
}

.dd-content ul {
    list-style-type: disc;
}

.angle-size {
    font-size: 24px;
    color: #ff942C;
    cursor: pointer;
}

/* .dropdown-button {
    background: none;
    border: none !important;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    margin: 0;
} */
.merchandise {
    margin-top: 5.5rem;
}

.payment-details-container {
    max-width: 600px;
    margin: 0 20px;
    font-family: Open Sans;
    margin-top: 5rem;
}

.payment-methods {
    display: flex;
    justify-content: center;
}

.payment-methods div {
    padding: 5px 10px;
    border: none;
    width: 8rem;
    font-weight: 600;
    text-align: center;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 4px;
}

.payment-methods div.active {
    background-color: #FF942C;
    color: white;
}

.payment-submit-button {
    margin: 0 auto;
    padding: 5px 10px;
    border: none;
    font-family: Open Sans;
    font-weight: 600;
    background-color: #31197C;
    color: #fff;
    cursor: pointer;
}

.payment-submit-button:hover {
    background-color: #31197C;
    color: #fff;
}

@media screen and (max-width:860px) {

    .carousel-control-prev,
    .carousel-control-next {
        display: none;
    }

    .adv-left-right-gap-d {
        margin-left: 0.8rem;
        margin-right: 0.5rem;
    }

    .article-left-right-d {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .adv-card {
        margin: 2px 0.2rem;
        padding: 8px;
        width: 105%;
        border: none;
        border-radius: 5px;
        overflow: hidden;
    }

    .adv-image-home-detail {
        max-width: 50px;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50px;
    }

    .bannerImg-d {
        width: 150px;
        height: 60px;
    }

    .arrow-adv {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 45px;
        padding: 2px;
        z-index: 1;
        position: relative;
    }

    .author-icon-d {
        width: 80px;
        margin-left: 0.5rem;
        margin-right: 0
    }

    .author-desc-d {
        margin-left: 0.3rem;
        margin-right: 0
    }

    .topic-art-d {
        margin-left: 0.3rem;
        margin-right: 0
    }

    .table-of-contents-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 104%;
        background-color: #fcecdd;
        padding: 10px;
        border-radius: 5px;
        font-size: 18px;
        font-family: Raleway, sans-serif !important;
        margin-left: 0.45rem;
        margin-right: 0;
    }

    .html-text-new-des {
        font-family: Raleway, sans-serif !important;
        font-style: normal;
        text-align: left;
        line-height: 24px;
        font-size: 15px;
        color: #000000;
        margin-left: 0.7rem;
        margin-right: 0;
        margin-top: 0.2rem;
    }

    .html-text-new-des.p-type {
        max-height: calc(20px * 14);
        overflow: hidden;
        position: relative;
    }

    .html-text-new-des.p-type:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 150px;
        /* Height of the fade effect */
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        /* Fading effect */
    }
    .html-text-new-des h1 {
        color: #31197C;
    }
    /* For "G" type, no limit, show content normally */
    .html-text-new-des.g-type {
        display: block;
    }

    .block-d {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        width: 103.5%;
    }

    /* .tshirt-img {
        width: 150px;
        height: 150px;
        margin-right: 0;
        margin-left: 0;
    } */
    .article-sm-img {
        margin-top: 0.5rem;
        width: 95%;
    }

    .tshirt-img {
        width: 100%;
        height: 100%;
        margin-right: 3px;
        margin-left: 0;
    }

    .tshirt-img1 {
        width: 100%;
        height: 100%;
        margin-right: 0;
        margin-left: 3px;
    }

    .tshirt {
        font-size: 18px;
        font-weight: 600;
        font-family: Open Sans;
    }

    .tshirt-left {
        padding-left: 0.5rem;
    }

    .ts-left-right-d {
        margin-left: 2.5rem;
        margin-right: 1rem;
    }

    .article_image-d {
        border-radius: 12px;
        background-color: white;
        padding: 0.5rem;
        margin-left: 1.5rem;
        margin-right: 0;
    }

    .table-content {
        font-weight: 600;
        font-family: Open sans;
        color: #ff942c;
        font-size: 16px;
    }

    .stickytable {
        position: sticky;
        top: 3.9rem;
        z-index: 10;
    }

    .dd-content {
        font-family: Raleway, sans-serif !important;
        background-color: #ffffff;
        padding: 5px 2px 1px 2px;
        margin-left: 0.5rem;
        width: 103%;
        margin-top: -0.1rem;
    }

    .dd-content ol {
        list-style-type: decimal;
        margin-left: 1.5rem;
        font-weight: 500;
        color: black;
        font-size: 14px !important;
        font-family: Raleway, sans-serif !important;
    }

    .table-of-contents ul {
        list-style-type: disc;
    }

    .latest-top-gap {
        margin-top: 0rem;
    }

    .poll-result-card-post-d {
        padding: 0.5rem;
        height: auto;
        background-color: #ffffff;
        /* background-color: #f6f0fa; */
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        margin: 0 0 0 0.6rem;
        border: none;
        /* width: 124%; */
        width: 115%;
    }

    .poll-answer-card-post-d {
        padding: 0.5rem 0.5rem 1rem 1.8rem;
        height: auto;
        background-color: #ffffff;
        /* background: #f6f0fa; */
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        margin: 0 0 0 0.6rem;
        border: none;
        /* width: 120%; */
        width: 115%;
    }

    .poll-question-card-post-d {
        padding: 0.5rem;
        height: auto;
        background: #fcecdd;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin: 0 0 0 0.6rem;
        border: none;
        /* width: 100%; */
        width: 115%;
    }

    .polloptions-card-post-d {
        margin-top: 0.5rem;
        width: 105%;
        height: 50px;
        display: flex;
        padding: 2px 5px;
        border-radius: 10px;
        margin-left: -1.1rem;
        border: 1px solid lightgrey;
    }

    .selected {
        /* background: #FFE6CE !important; */
        border: 1px solid #DB6C00;
    }

    .container_class {
        width: 100%;
        height: 7vh;
        background-color: white;
        border-radius: 10px;
        font-family: Open Sans;
        margin: 10px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid lightgrey;
    }

    .poll-question-card-post-dd {
        padding: 0.5rem;
        height: auto;
        background: #fcecdd;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin: 0 0 0 0.5rem;
        border: none;
        width: 104%;
    }

    .latest-dd {
        padding: 0.5rem;
        height: auto;
        background: #fcecdd;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin: 0 0 0 0.5rem;
        border: none;
        width: 104%;
    }

    .become-a-member-dd-m {
        padding: 0.5rem;
        height: auto;
        background: #fcecdd;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin: 0 0.5rem;
        border: none;
        width: 95%;
    }

    .latest-art-card-d {
        padding: 5px;
        width: 103%;
        margin-left: 3%;
        margin-right: 1%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border: none;
        margin-top: 1px;
        /* box-shadow: 2px 2px 6px #bebebe, -2px -2px 6px #ffffff; */
    }

    .articles-Published-d-m {
        border-radius: 10px;
        height: auto;
        width: 96%;
        margin: 2px;
        padding: 5px;
        border: none;
        align-items: center;
    }

    .articles-Published-dd-m {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        height: auto;
        width: 95%;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding: 5px 8px;
        border: none;
        align-items: center;
    }

    .text-in-about-d-m {
        font-weight: 600;
        font-size: 14px;
        padding: 8px 5px;
        font-family: Open Sans;
        color: #5819a8;
    }

    .bold-text-d-m {
        font-weight: 700;
        margin: 5px;
        color: #FF942C;
        font-size: 20px;
    }

    .articles-graph-d-m {
        border-radius: 10px;
        height: auto;
        width: 103%;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding: 10px;
        border: none;
    }

    .scanner-card-d-m {
        border-radius: 10px;
        height: auto;
        width: 90%;
        margin-left: 5%;
        padding: 10px;
        border: none;
    }

    .sceem-logo-download-new-d-m {
        width: 50%;
        margin-top: 1px;
        margin-left: 1px;
    }

    .scanner-text-d-m {
        font-weight: 600;
        color: black;
        font-size: 13px;
        font-family: Open Sans;
        padding: 3px;
    }

    .scanner-text-sm-d-m {
        font-weight: 600;
        color: black;
        font-size: 12px;
        font-family: Open Sans;
        padding: 3px;
    }

    .powered-by-nebutech-new-d-m {
        margin-top: 0.8rem;
        width: 40%;
    }

    .top-articles-per-topic-d-m {
        font-weight: 600;
        color: black;
        font-size: 16px;
        font-family: Open Sans;
    }

    .art-d::before {
        content: '';
        display: block;
        height: 22.3rem;
        margin-top: -22.3rem;
        visibility: hidden;
    }

    .art-d-poll::before {
        content: '';
        display: block;
        height: 7.3rem;
        margin-top: -7.3rem;
        visibility: hidden;
    }

    .merchandise {
        margin-top: 1.5rem;
    }

    .payment-details-container {
        max-width: 100%;
        margin: 0 0 0 18px;
        font-family: Open Sans;
        margin-top: 0;
    }

    .payment-methods {
        display: flex;
        justify-content: center;
    }

    .payment-methods div {
        padding: 5px 10px;
        border: none;
        width: 8rem;
        font-weight: 600;
        text-align: center;
        background-color: #f0f0f0;
        cursor: pointer;
        border-radius: 4px;
    }

    .payment-methods div.active {
        background-color: #FF942C;
        color: white;
    }

    .payment-submit-button {
        margin: 0 auto;
        padding: 5px 10px;
        border: none;
        font-family: Open Sans;
        font-weight: 600;
        background-color: #31197C;
        color: #fff;
        cursor: pointer;
    }

    .payment-submit-button:hover {
        background-color: #31197C;
        color: #fff;
    }

}