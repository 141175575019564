.sm-text {
    font-size: 11.5px;
    opacity: 0.4;
}

.showPassword {
    position: sticky;
    float: right;
    margin-top: -2.4rem;
    padding: 5px;
    margin-right: 0.5rem;
}

.tick-color-green {
    color: rgb(6, 155, 6);
}

.tick-color-red {
    color: rgb(222, 20, 20);
}

.login-credentials {
    border-radius: 6px;
    float: left;
    font-size: 15px;
    font-family: Open Sans;
    color: #31197C;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;
}

.box-form {
    border: 1px solid rgb(210, 207, 207);
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
}

.login-btn {
    width: 100% !important;
    height: 2.5rem;
    color: white;
    border-radius: 8px;
    border-color:#31197C;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%) !important;
}

.login-btn:hover {
    width: 100%;
    height: 2.5rem;
    color: white;
    border-radius: 8px;
    border-color:#31197C;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}


.back_arrow_ {
    top: 10.2%;
    margin-left: 1.6rem;
    width: 9%;
    position: fixed;
}



.form-bg {
    background: #ECE9F3;
    border-radius: 8px;
    height: 2.5rem;
}

.form-bg:hover {
    background: #ECE9F3;
    border-radius: 8px;
    height: 2.5rem;
}

._sceem {
    font-family: Open Sans;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    color: #FF942C;
}

/* .form-signup {
    position: relative;
    top: 6.5rem;
} */

.signup-terms {
    font-size: 12px;
    font-family: Open Sans;
    font-weight: normal;
    float: left;
}

.error {
    color: red;
    font-size: 0.625em;
    float: left;
}

.errormsg {
    color: red;
    font-size: 0.625em;
    float: center;
}

.signup-agree-text {
    position: absolute;
    margin-top: -2px;
    margin-left: 2px;
}

.signup-termslink-color {
    color: #FF942C;
}