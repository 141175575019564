/* .papers {
  background-color: #fff;
 padding: 2em 2em;
  box-shadow: inset 0 0 30px rgba(0,0,0,0.1), 1px 1px 3px rgba(0,0,0,0.2); 
} */

/* .k-more {
  height: 100vh;
  overflow-y: auto;
} */

/* .papers {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 90%;
  padding: 0 2em;
  margin: 50px auto;
  height: calc(100vh - 100px);
  overflow-y: auto; 
  z-index:100;
}

.papers h2 {
  font-size: 30px;
  font-weight: normal;
  text-align: center;
  padding: 0.2em 0;
  margin: 0;
  border-top: 1px solid #ddd;
  border-bottom: 2px solid #ddd;
  position: sticky; 
  top: 0; 
  background-color: white; 
  z-index: 1; 
} */
.detailed-event-text {
  font-size: 18px;
  font-weight: 600;
  font-family: Open Sans;
}

.detailed-adv-card {
  background: linear-gradient(to bottom, #f7e3d7, #fcf2f0, #ebe7f5, #eef2fb);
  border-radius: 35px 35px 0 0;
  padding-top: 5px;
  margin: 3rem 4% 0 4%;
  width: 80%;
  height: 85vh;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  font-family: Open Sans;
}

.detailed-adv-img-border {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  border: 3px solid #31197C;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: -3px;
  margin-left: 9px;
}

.adv-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adv-image-container img {
  max-width: 80px;
  max-height: 80px;
  border-radius: 10%;
}

.adv-fix {
  background: white;
  position: fixed;
  top: 9%;
  width: 31%;
  left: 7%;
  right: 2%;
  z-index: 1000
}

.camp-name-home {
  margin-right: 20px;
  margin: 3px 5px;
  font-weight: 500;
  font-family: Open Sans;
  color: black;
  font-size: 13px;
}

.camp-col {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  color: white;
  font-size: 14px;
}
.adv-image-home {
  max-width: 80px;
  padding: 3px;
  margin-top: -5px;
}
.createopportunity-card {
  border-radius: 10px;
  height: auto;
  width: 100%;
  margin-bottom: 8px;
  border: none;
  background: #f7f7f5;
}
.custom-modal {
  z-index: 1050;
}
.opp-row-width {
  width: 99.4%;
}

.job-card_ {
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
}

.opp-fs {
  font-size: 12px;
  height: 2.4rem;
  padding: auto 2px;
}

.custom-form-control {
  padding-left: auto !important;
  padding-right: auto !important;
}

.opp-date {
  margin-top: -0.5px;
  height: 2.4rem;
  font-size: 12px;
}

.opp-date:hover {
  margin-top: -0.5px;
  height: 2.4rem;
  font-size: 12px;
}

.papers {
  background-color: #fff;
  padding: 1em 1em 1em 1em;
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.papers {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 90%;
  margin: 1em 1em 1em 5em;
  z-index: 100;
}

section {
  position: relative;
  display: flex;
  flex-direction: column;
}

/* section:nth-of-type(1) {
  background-color: #3F9EBC;
}

section:nth-of-type(2) {
  background-color: #F7B744;
}

section:nth-of-type(3) {
  background-color: #8ECAE6;
}

section:nth-of-type(4) {
  background-color: #F1853B;
}

section:nth-of-type(5) {
  background-color: #79C8B0;
} */

.section-heading {
  /* position: sticky;
  top: 4rem;
  width: 100%;
  background-color: #2E3537;
  box-shadow: 0 0 3px black;
  z-index: 999;
  padding: 5px 15px; */
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  padding: 0.2em 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 4rem;
  background-color: white;
  z-index: 1;
  margin: 1rem 3rem;
}

.section-heading h2 {
  margin: 5px 0;
  color: white;
}

.section-content {
  padding: 1rem 3rem;
  height: auto;
}

/* .papers  {
  position: relative;
  width: 90%;
  padding: 2em;
  margin: 50px auto;
} */

/* .papers h2  {
  font-size: 1.8em;
  font-weight: normal;
  text-align: center;
  padding: 0.2em 0;
  margin: 0;
  border-top: 1px solid #ddd;
  border-bottom: 2px solid #ddd;
} */

/* .papers:before, .papers:after  {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: rotateZ(2.5deg);
  -o-transform: rotate(2.5deg);
  transform: rotateZ(2.5deg);
  z-index: -1;
}
.papers:after  {
  -webkit-transform: rotateZ(-2.5deg);
  -o-transform: rotate(-2.5deg);
  transform: rotateZ(-2.5deg);
} */
.papers h3 {
  font-size: 1em;
  background-color: #EEEEFF;
  color: #31197C;
  font-weight: bold;
  text-align: left;
  padding: 0.2em 0;
  margin: 0em;
  border-bottom: 1px dotted #ddd;
}

.papers p {
  text-align: left;
  margin: 1, 5em 0;
}
.show-adv-top {
  margin-top: 110px;
}

.no-adv-top {
  margin-top: 60px;
}
.Article_Image_width {
  width: 80%;
  height: 100%;
  margin-left: 0px;
  border: none;
  border-radius: 0px;
  /* width: 400px;
  height: 250px;
  position: relative;
  left: -185px; */
}

.art-poll-text-h {
  font-size: 18px;
  font-family: Open Sans;
  font-weight: 600;
  color: #31197c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topicName-rightAlign {
  position: absolute;
  right: 18px;
}

.link-image-mob {
  width: 15%;
  height: 15%;
  margin: 10px 0;

}

.link-image-mob_ {
  width: 45%;
  height: 45%;
  margin: 10px 0;
}

.link-image-mob1_ {
  width: 60%;
  height: 60%;
  margin: 10px 0;
}

.know-more-lr {
  align-items: left;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-family: Open Sans !important;
}

.heading-color {
  color: #31197C;
}

.title_text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 1000;
  font-size: 25px;
  line-height: 28px;
  color: black;
  margin-left: 0.3rem;
}

._heading {
  font-family: Raleway, sans-serif !important;
  font-style: normal;
  font-weight: 1000;
  font-size: 22px;
  text-align: left;
  line-height: 28px;
  color: #FF942C;
  /* position: absolute;
  top: 90%; */
  margin-left: 0.3rem;
}

.tagline {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: black;
  margin-left: 0.1rem;
}

.author-icon {
  width: 130%;
  margin-left: 0.3rem;
}

.authorName-leftAlign {
  margin-left: 0.2rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: black;
  display: flex;
  width: 80%;
}

.authorName-d {
  margin-left: 0.2rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: black;
}

.pubDate-d {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: black;
  margin-top: 5px;
  margin-left: 0.1rem;
}

.html-text-new {
  /* font-family: Raleway, sans-serif !important;
  font-style: normal;
  text-align: justify;
  line-height: 20px;
  font-size: 15px;
  color: #000000;
  margin-left: 0.1rem;
  margin-top: 0.2rem; */
  font-family: Raleway, sans-serif !important;
  font-style: normal;
  text-align: left;
  line-height: 24px;
  font-size: 15px;
  color: #000000;
  margin-left: 0.1rem;
  margin-top: 0.2rem;
}

.g-play {
  width: 20%;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.app-background {
  width: 100%;
  height: auto;
}

.app-border {
  position: absolute;
  left: 10%;
  right: 10%;
  align-items: center;
  justify-content: center;
  margin-top: -28.5%;
  border: 1.5px solid white;
  border-radius: 15px;
  width: 80%;
  height: 12.9%;
}

.mr-ml {
  margin-right: 0px;
  margin-left: 0px;
}

.background-new {
  width: 100%;
  height: 26%;
  font-family: Open Sans;
}

/* .bg-border-top {
  position: absolute;
  bottom: 2rem;
} */

.app-border-top {
  margin-left: 4%;
  margin-right: 4%;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #31197C;
  border-radius: 15px;
  width: 96%;
  height: 100%;
}

.sceem-logo-download-new {
  width: 30%;
  margin-top: 0.4rem;
  margin-left: 0.4rem;
}

.powered-by-nebutech-new {
  margin-top: 0.8rem;
  margin-left: 75%;
  margin-bottom: 5px;
  width: 22%;
}

.download-text-new {
  margin-top: -2.5rem;
  font-size: 10px;
  color: black;
  font-weight: 400;
  font-family: Open Sans !important;
}

.googleplay-new {
  margin-top: 5px;
  width: 23%;
}

.powered-by {
  position: relative;
  margin-top: -2.8rem;
  left: 86%;
  width: 12%;
}

.article-chain-text {
  margin-top: 5%;
  font-size: 14px;
  color: #31197C;
  font-weight: 600;
}

.box-text-lp {
  margin-top: 0px;
  font-size: 14px;
  color: #31197C;
  font-weight: 600;
}

.become-member {
  margin-top: 6px;
  font-size: 22px;
  color: #FF942C;
  font-weight: 600;
}


.download-text {
  margin-top: -4.3rem;
  font-size: 12.5px;
  color: white;
  font-weight: 600;
  font-family: Open Sans;
}

.download-text-sm {
  margin-top: 0.1rem;
  font-size: 10px;
  color: white;
  font-weight: 400;
  font-family: Open Sans;
}

.googleplay {
  margin-top: -10px;
  width: 23%;
}

.powered-by-nebutech {
  position: relative;
  margin-top: 1.85rem;
  left: 2%;
  width: 50%;
}

.sceem-logo-download {
  width: 10%;
  margin-top: 0.4rem;
  margin-left: 0.4rem;
}

.articles-Published {
  border-radius: 10px;
  height: 106px;
  width: 90px;
  margin-left: 1px;
  padding: 12px 0 5px 0;
  border: 2px solid rgb(228, 225, 225);
}

.bold-text {
  font-weight: 700;
  font-size: 18px;
  color: #FF942C;
  font-family: Open Sans;
}

.stats-head {
  margin-left: -0.9rem;
  color: #FF942C;
  margin-top: 0.7rem;
  font-family: Open Sans;
  font-weight: 600;
}

.video {
  width: 50%;
  height: 50%;
}

.explore-more-text {
  font-size: 14px;
  font-weight: 600;
}

.text-in-about {
  font-weight: 600;
  font-size: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  font-family: Open Sans;
}

.text-in-about-e {
  font-weight: 600;
  font-size: 13px;
  margin-left: 3px !important;
  margin-right: 3px !important;
  margin-top: 3px;
  font-family: Open Sans;
}

.articles-graph {
  border-radius: 10px;
  height: auto;
  width: 277px;
  padding: 12px 5px 5px 5px;
  margin-left: 8px;
  margin-right: 0.8rem;
  border: 2px solid rgb(228, 225, 225);
}

.campaigns-polls {
  border-radius: 10px;
  height: 85px;
  width: 92px;
  padding: 12px 0 5px 0;
  margin-left: 1px;
  border: 2px solid rgb(228, 225, 225);
}

.eminent-e {
  border-radius: 10px;
  height: 85px;
  width: 188px;
  padding: 12px 0 5px 0;
  margin-left: 0;
  border: 2px solid rgb(228, 225, 225);
}

.eminent-authors {
  border-radius: 10px;
  height: auto;
  width: 280px;
  padding: 12px 5px 5px 5px;
  margin-left: 8px;
  margin-right: 0.8rem;
  border: 2px solid rgb(228, 225, 225);
}

.questions- {
  border-radius: 10px;
  height: 80px;
  width: 100px;
  padding: 12px 0 5px 0;
  margin-left: 7px;
  border: 2px solid rgb(228, 225, 225);
}

.questions-authors {
  border-radius: 10px;
  height: 80px;
  width: 170px;
  padding: 12px 0 5px 0;
  border: 2px solid rgb(228, 225, 225);
}

.img-know-more-width {
  width: 40%;
  height: 40%;
  margin: 10px 0;
}

.img-know-more-width-f {
  width: 30%;
  height: 30%;
  margin: 10px 0;
}

.adv-text-details {
  height: 22vh;
  overflow-y: auto;
  font-size: 11px;
}
@media screen and (max-width:860px) {
  .adv-text-details {
    height: 31vh;
    overflow-y: auto;
    font-size: 12px;
  }
  .show-adv-top {
    margin-top: 110px;
  }

  .no-adv-top {
    margin-top: 35px;
  }

  .Article_Image_width {
    width: 55%;
    height: 100%;
    margin-left: 8%;
    border: 1px solid #bebebe;
    border-radius: 10px;
  }

  .topicName-rightAlign {
    position: absolute;
    right: 18px;
  }

  .title_text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 28px;
    color: black;
    margin-left: 0.5rem;
  }

  ._heading {
    /* font-family: Open Sans !important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    padding: 5px;
    text-align: justify;
    line-height: 28px;
    color: #FF942C;
    margin-left: 6%; */
    font-family: Raleway, sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FF942C;
    margin-top: 5px;
    text-align: left;
    margin-left: 1.6rem;
  }

  .tagline {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: black;
    margin-left: 0.8rem;
  }

  .author-icon {
    width: 20%;
    margin-left: 0.7rem;
  }

  .authorName-d {
    margin-left: 0.7rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: black;
  }

  .authorName-leftAlign {
    margin-left: 0.7rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: black;
    display: flex;
    width: 80%;
  }

  .pubDate-d {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: black;
    margin-top: 5px;
    margin-left: 0.7rem;
  }

  .html-text-new {
    font-family: Raleway, sans-serif !important;
    font-style: normal;
    text-align: left !important;
    line-height: 24px;
    font-size: 15px;
    color: #000000;
    margin-left: 7%;
    margin-top: 0.2rem;
  }

  .g-play {
    width: 20%;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .app-background {
    width: 100%;
    height: auto;
  }

  .app-border {
    position: absolute;
    left: 10%;
    right: 10%;
    align-items: center;
    justify-content: center;
    margin-top: -30%;
    border: 2px solid white;
    border-radius: 15px;
    width: 80%;
    height: 15.8%;
  }

  /* .app-border-top {
    position: absolute;
    left: 10%;
    right: 10%;
    align-items: center;
    justify-content: center;
    margin-top: -28.5%;
    border: 1.5px solid white;
    border-radius: 15px;
    width: 80%;
    height: 28%;
  } */
  .mr-ml {
    margin-right: 8px;
    margin-left: 8px;
  }

  .background-new {
    width: 100%;
    height: 26%;
    font-family: Open Sans;
  }

  /* .bg-border-top {
    position: relative;
    bottom: 0.1rem;
  } */

  .app-border-top {
    margin-left: 6%;
    margin-right: 8%;
    align-items: center;
    justify-content: center;
    margin-top: -3.5%;
    border: 1.5px solid #31197C;
    border-radius: 15px;
    width: 96.5%;
    height: 40%;
  }

  .sceem-logo-download-new {
    width: 24%;
    margin-top: 0.4rem;
    margin-left: 0.4rem;
  }

  .powered-by-nebutech-new {
    margin-top: 0.7rem;
    margin-left: 74%;
    margin-bottom: 5px;
    width: 25%;
  }

  .download-text-new {
    margin-top: -2.8rem;
    font-size: 10px;
    color: black;
    font-weight: 400;
    font-family: Open Sans !important;
  }

  .googleplay-new {
    margin-top: 3px;
    width: 23%;
  }

  .article-chain-text {
    margin-top: 2.9%;
    font-size: 14px;
    color: #31197C;
    font-weight: 600;
  }

  .become-member {
    margin-top: 0px;
    font-size: 22px;
    color: #FF942C;
    font-weight: 600;
  }

  .box-text-lp {
    margin-top: 0px;
    font-size: 14px;
    color: #31197C;
    font-weight: 600;
  }

  .powered-by {
    position: relative;
    margin-top: -2.8rem;
    left: 86%;
    width: 12%;
  }

  .download-text {
    margin-top: -5.8rem;
    font-size: 18px;
    color: white;
    font-weight: 600;
    font-family: Open Sans;
  }

  .download-text-sm {
    margin-top: 0.2rem;
    font-size: 12px;
    color: white;
    font-weight: 400;
    font-family: Open Sans;
  }

  .googleplay {
    width: 23%;
    margin-top: 2px;
  }

  .powered-by-nebutech {
    position: relative;
    margin-top: 3rem;
    left: 2%;
    width: 22%;
  }

  .sceem-logo-download {
    width: 10%;
    margin-top: 0.4rem;
    margin-left: 0.4rem;
  }

  .articles-Published {
    border-radius: 10px;
    height: 125px;
    width: 26.7vw;
    margin-left: 3.8vw;
    padding: 12px 0 5px 0;
    border: 2px solid rgb(228, 225, 225);
  }

  .bold-text {
    font-weight: 700;
    font-size: 22px;
    color: #FF942C;
    font-family: Open Sans;
  }

  .stats-head {
    margin-left: 0.2rem;
    color: #FF942C;
    margin-top: 0.7rem;
    font-family: Open Sans;
    font-weight: 600;
  }

  .video {
    width: 100%;
    height: 100%;
  }

  .text-in-about {
    font-weight: 600;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    font-family: Open Sans;
  }

  .text-in-about-e {
    font-weight: 600;
    font-size: 13px;
    margin-left: auto;
    margin-right: auto;
    font-family: Open Sans;
  }

  .articles-graph {
    border-radius: 10px;
    height: auto;
    width: 89vw;
    padding: 12px 5px 5px 5px;
    margin-left: 1.4rem;
    margin-right: 0.8rem;
    border: 2px solid rgb(228, 225, 225);
  }

  .campaigns-polls {
    border-radius: 10px;
    height: 90px;
    width: 26.5vw;
    padding: 12px 0 5px 0;
    margin-left: 4vw;
    border: 2px solid rgb(228, 225, 225);
  }

  .eminent-e {
    border-radius: 10px;
    height: 90px;
    width: 57.5vw;
    padding: 12px 0 5px 0;
    margin-left: 12px;
    border: 2px solid rgb(228, 225, 225);
  }

  .questions- {
    border-radius: 10px;
    height: 100px;
    width: 32.4vw;
    padding: 12px 0 5px 0;
    margin-left: 14px;
    border: 2px solid rgb(228, 225, 225);
  }

  .questions-authors {
    border-radius: 10px;
    height: 100px;
    width: 57.2vw;
    padding: 12px 0 5px 0;
    border: 2px solid rgb(228, 225, 225);
  }

  .eminent-authors {
    border-radius: 10px;
    height: auto;
    width: 89.5vw;
    padding: 12px 5px 5px 5px;
    margin-left: 1.4rem;
    margin-right: 0.8rem;
    border: 2px solid rgb(228, 225, 225);
  }

  .img-know-more-width {
    width: 90%;
    height: 90%;
    margin: 10px 20px;
  }

  .img-know-more-width-f {
    width: 80%;
    height: 80%;
    margin: 10px 20px;
  }

  .know-more-lr {
    align-items: left;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    font-family: Open Sans !important;
  }

  .link-image-mob {
    width: 90%;
    height: 90%;
    margin: 10px 9px;
  }

  .link-image-mob_ {
    width: 100%;
    height: 100%;
    margin: 10px 14px;
  }

  .link-image-mob1_ {
    width: 100%;
    height: 100%;
    margin: 10px 14px;
  }

  section {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .section-heading {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    padding: 0.2em 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 4rem;
    background-color: white;
    z-index: 1;
    margin: 1rem 0;
  }

  .section-heading h2 {
    margin: 5px 0;
    color: white;
  }

  .section-content {
    padding: 1rem;
    height: auto;
  }

  .papers {
    background-color: #fff;
    padding: 0.2rem;
    box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);
  }

  .papers {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 90%;
    margin: 0.5em 0.5em 0.5em 1em;
    z-index: 100;
  }

  .detailed-adv-card {
    background: linear-gradient(to bottom, #f7e3d7, #fcf2f0, #ebe7f5, #eef2fb);
    border-radius: 35px 35px 0 0;
    padding-top: 5px;
    margin: 3rem 4% 0 4%;
    width: 92%;
    height: 88vh;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    font-family: Open Sans;
  }

  .opp-row-width {
    width: 97%;
  }

  .opp-fs {
    font-size: 12px;
    height: 2.4rem;
    padding: auto 2px;
  }

  .opp-date {
    margin-top: -1px;
    height: 2.4rem;
    font-size: 12px;
  }

  .opp-date:hover {
    margin-top: -1px;
    height: 2.4rem;
    font-size: 12px;
  }

  .custom-form-control {
    padding-left: 0.35rem !important;
    padding-right: 0.35rem !important;
  }

  .job-card_ {
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
  }

  .adv-fix {
    background: white;
    position: fixed;
    top: 8.5%;
    width: 94%;
    left: 3%;
    right: 3%;
    z-index: 1000
  }

  .camp-name-home {
    margin-right: 20px;
    margin: 3px 5px;
    font-weight: 500;
    font-family: Open Sans;
    color: black;
    font-size: 13px;
  }

  .camp-col {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    color: white;
    font-size: 14px;
  }

  /* .papers, .papers:before, .papers:after  {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: inset 0 0 30px rgba(0,0,0,0.1), 1px 1px 3px rgba(0,0,0,0.2);
  }
  .papers  {
    position: relative;
    width: 90%;
    padding: 1em 0.5em;
    margin: 20px auto;
  }
  .papers:before, .papers:after  {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: rotateZ(2.5deg);
    -o-transform: rotate(2.5deg);
    transform: rotateZ(2.5deg);
    z-index: -1;
  }
  .papers:after  {
    -webkit-transform: rotateZ(-2.5deg);
    -o-transform: rotate(-2.5deg);
    transform: rotateZ(-2.5deg);
  }
  .papers h2  {
    font-size: 1.8em;
    font-weight: normal;
    text-align: center;
    padding: 0.2em 0;
    margin: 0;
    border-top: 1px solid #ddd;
    border-bottom: 2px solid #ddd;
  }
  .papers h3  {
    font-size: 1em;
    background-color:#EEEEFF;
    color:#31197C;
    font-weight: bold;
    text-align: left;
    padding: 0.2em 0;
    margin: 0em;
    border-bottom: 1px dotted #ddd;
  }
  .papers p  {
    text-align: left;
    margin: 1,5em 0;
  } */
}