.header-logo {
    margin-left: 2.5rem;
    margin-top: -2px;
    width: 35%;
}

.header-height {
    height: 4rem;
    background-color: #31197c;
}

.sceem-org {
    margin-right: 4.2%;
}

.p-signup-btn {
    background-color: white;
    width: 6rem;
    height: 30px;
    border-color: #31197C;
    border-radius: 6px;
    text-align: center;
    padding-top: 4px;
    color: #31197C;
    margin-right: 0.7rem;
    font-size: small;
}

.p-signup-btn:hover {
    background-color: white;
    border-color: #31197C;
    color: #31197C;
}

@media screen and (max-width:960px) {
    .p-signup-btn {
        background-color: white;
        width: 6rem;
        height: 30px;
        border-color: #31197C;
        border-radius: 6px;
        text-align: center;
        padding-top: 4px;
        color: #31197C;
        margin-right: 0.4rem;
        font-size: small;
    }

    .p-signup-btn:hover {
        background-color: white;
        border-color: #31197C;
        color: #31197C;
    }

    .header-logo {
        margin-left: -10px;
        margin-top: -4px;
        width: 45%;
    }

    .header-height {
        height: 4rem;
        background-color: #31197c;
    }

    .sceem-org {
        margin-right: 0.1%;
    }
}