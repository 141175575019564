.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading-bg{
    z-index: 550000 !important;
    background: white !important;
    width:100%;
    height:100vh;
    position: relative;
}
.square {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 50px;
    height: 52px;
}

.circle {
    position: absolute;
    right: 0;
    top: 110px;
    width: 40px;
    height: 60px;
}

.circle-yellow {
    position: absolute;
    left: 0;
    top: 40%;
    width: 25px;
    height: 60px;
}

.logo-gif {
    position: absolute;
    top: 35% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    background-color: #ffffff;
    animation: mymove;
    animation-duration: 5s;
    transition: 3s ease-in-out;
}

@keyframes mymove {
    from {
        top: 180px;
    }

    to {
        top: 180px;
    }
}

.circle-blue {
    position: absolute;
    top: 80%;
    left: -1px;
    width: 25px;
    height: 60px;
}

.nebutech {
    position: absolute;
    top: 80% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15%;
}

.isocertified {
    position: absolute;
    top: 85% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12%;
}

.circle-reverse-yellow {
    position: absolute;
    top: 70%;
    width: 25px;
    height: 60px;
    right: -1px;
}

.square-new {
    position: absolute;
    top: 80px;
    right: 20px;
    width: 50px;
    height: 50px;
}

@media screen and (max-width:639px) {
    .square {
        position: absolute;
        top: 30px;
        left: 30px;
        width: 50px;
        height: 52px;
    }

    .circle {
        position: absolute;
        right: 0;
        top: 110px;
        width: 40px;
        height: 60px;
    }

    .circle-yellow {
        position: absolute;
        left: 0;
        top: 38%;
        width: 25px;
        height: 60px;
    }

    .logo-gif {
        position: absolute;
        /* width: 50%;
        height: 22%; */
        width: 80%;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 40% !important;
        background-color: #ffffff;
        animation: mymove;
        animation-duration: 5s;
        transition: 3s ease-in-out;
    }

    @keyframes mymove {
        from {
            top: 180px;
        }

        to {
            top: 180px;
        }
    }

    .circle-blue {
        position: absolute;
        top: 80%;
        left: -2px;
        width: 25px;
        height: 60px;
    }

    .nebutech {
        /* position: relative;
        top: 450px;
        left: 7%;
        right: 7%;
        width: 207px;
        height: 64px; */
        position: absolute;
        top: 65% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 35%;
    }

    .isocertified {
        /* position: relative;
        top: 450px;
        left: 33%;
        right: 25%;
        width: 36%; */
        position: absolute;
        top: 80% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 36%;
    }

    .circle-reverse-yellow {
        position: absolute;
        top: 65%;
        width: 25px;
        height: 60px;
        right: -1px;
    }

    .square-new {
        position: absolute;
        top: 80px;
        right: 20px;
        width: 50px;
        height: 50px;
    }
}