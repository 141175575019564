.h-logo {
    margin-left: 2.5rem;
    cursor: pointer;
    width: 34%;
    margin-bottom: -0.4rem;
    margin-top: 4px;
}

.nav-height {
    height: 3.4rem;
}

.nav-margin-right {
    margin-right: 1.6rem;
}

.nav-menu1 {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 8px;
    padding: 10px 8px;
    list-style: none;
    text-align: center;
    text-decoration: none;
    width: 100vw;
    justify-content: end;
    margin-right: 1rem;
    margin-top: -2.9rem;
    font-size: 12px;
}

.nav-links1 {
    font-weight: 600;
    color: white;
    transition: all 0.2s ease-out;
    font-family: Open Sans !important;
    font-size: 12px;
    padding: 6px 0px;
    margin-left: 1.5px;
    margin-right: 1.5px;
    border: 1px solid white;
    border-radius: 8px;
    width: 85px;
    display: inline-block;
    text-align: center;
}

.nav-links-signUpbtn {
    background-color: #31197C !important;
    width: 6rem;
    height: 30px;
    border-color: #31197C !important;
    border-radius: 6px;
    text-align: center;
    font-family: Open Sans !important;
    margin: auto;
    margin-left: 2.2rem;
    padding-top: 4px;
    color: white;
    font-size: small;
}

.nav-links-signUpbtn:hover {
    background-color: #31197C;
    border-color: #31197C;
    color: white;
}

.nav-links1:hover {
    font-weight:600;
    color: white;
    transition: all 0.2s ease-out;
    text-decoration: none;
}

.fa-bars {
    margin-top: -0.5rem;
}

.menu_icon1 {
    display: none;
}

.menu_icon1 i {
    position: absolute;
    top: 10%;
    right: 10px;
    -webkit-transform: translateY(75%);
    -ms-transform: translateY(75%);
    transform: translateY(75%);
    z-index: 1;
}
.header-height1 {
    height: 3rem;
    background-color: #ff942c;
    position: fixed;
    top: 9%;
    z-index: 1;
    width:100%;
}
.know-fixed{
    font-size:30px;
    font-weight:600;
    color:white;
    margin-left: 0;
    margin-top: 1px;
    /* margin-left:-2rem; */
    /* margin-top: 1.6rem; */
    /* margin-left:1rem; */
}
.explore-top{
    display: flex;
    justify-content: space-between;
    width:54%;
}
@media screen and (max-width:960px) {
    .header-height1 {
        height: 3rem;
        background-color: #ff942c;
        position: fixed;
        top: 9%;
        z-index: 1;
        width:100%;
    }
    .know-fixed{
        font-size:24px;
        font-weight:600;
        color:white;
        margin-left: 6rem;
        margin-top: 0rem;
        margin-bottom: 1.2rem;
        /* margin-top: 0.5rem; */
        /* margin-left:0; */
    }
    .explore-top{
        display: flex;
        justify-content: space-between;
        width:50%;
    }
    .NavBarItems {
        position: relative;
    }

    .nav-menu1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 50px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu1.active {
        background-color: rgb(177, 175, 175);
        left: 0;
        top: 6rem;
        height: auto;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        color: black;
    }

    .nav-links1 {
        /* font-weight:600;
        text-align: center;
        padding: 0.5rem;
        width: 100%;
        display: table;
        font-family: Open Sans !important; */
        font-weight: 600;
        color: white;
        font-family: Open Sans !important;
        padding: 5px;
        margin-left: 0;
        margin-right: 0;
        margin-top:0;
        border: 1px solid white;
        border-radius: 10px;
        width: 100px;
        display: table;
        text-align: center;
        align-items: center;
    }

    .h-logo {
        position: absolute;
        left: -3.5rem;
        margin-top: -2.8rem;
        transform: translate(25%, 50%);
        width: 18%;
    }

    .nav-height {
        height: 3.5rem;
        box-shadow: 0px 1px 10px #999;
    }

    .menu_icon1 {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .menu_icon1 i {
        position: absolute;
        top: 10%;
        right: 15px;
        -webkit-transform: translateY(75%);
        -ms-transform: translateY(75%);
        transform: translateY(75%);
        z-index: 1;
    }

    .fa-times {
        margin-top: -0.5rem;
    }

    .bar-color {
        color: white;
        margin-top: -0.2rem;
    }

    .nav-links-signUpbtn {
        display: block;
        text-align: center;
        margin: auto;
        border-radius: 6px;
        width: 8rem;
        height: auto;
        background: #31197C;
        text-decoration: none;
        font-family: Open Sans !important;
        color: white;
        border-color: #31197C;
        padding-top: 5px;
        padding-bottom: 9px;
    }
}

@media screen and (max-width:639px) {
    .header-height1 {
        height: 3rem;
        background-color: #ff942c;
        position: fixed;
        top: 8%;
        z-index: 1;
        width:100%;
    }
    .know-fixed{
        font-size:24px;
        font-weight:600;
        color:white;
        margin-left:5.1rem;
        margin-top: 1px;
        /* margin-top: 0.1rem; */
        /* margin-left:0; */
    }
    .explore-top{
        display: flex;
        justify-content: space-between !important;
        width:110%;
    }
    .NavBarItems {
        position: relative;
    }

    .nav-menu1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 25px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu1.active {
        background-color: rgb(177, 175, 175);
        left: 0;
        top: 6rem;
        height: auto;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        color: black;
        align-items: center;
    }

    .nav-links1 {
        font-weight: 600;
        color: white;
        font-family: Open Sans !important;
        font-size: 14px;
        padding: 5px;
        margin-left: 0;
        margin-right: 0;
        margin-top:8px;
        border: 1px solid white;
        border-radius: 10px;
        width: 100px;
        display: table;
        text-align: center;
        align-items: center;
        justify-content: center;
        line-height: 2;
    }
    .h-logo {
        /* position: absolute;
        left: -3.5rem;
        margin-top: -2.4rem;
        transform: translate(25%, 50%);
        width: 22%; */
        margin-top: -0.8rem;
        margin-bottom: 1rem;
        margin-left:2.6rem;
        cursor: pointer;
        width: 25%;
   
    }

    .nav-height {
        height: 3.5rem;
        box-shadow: 0px 1px 10px #999;
    }

    .menu_icon1 {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .menu_icon1 i {
        position: absolute;
        top: 10%;
        right: 15px;
        -webkit-transform: translateY(75%);
        -ms-transform: translateY(75%);
        transform: translateY(75%);
        z-index: 1;
    }

    .nav-links-signUpbtn {
        display: block;
        text-align: center;
        margin: auto;
        border-radius: 6px;
        width: 8rem;
        height: auto;
        background: #31197C;
        font-family: Open Sans !important;
        text-decoration: none;
        color: white;
        border-color: #31197C;
        padding-top: 5px;
        padding-bottom: 9px;
    }
}