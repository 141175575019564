.no-connection {
  position: absolute;
  top: 45%;
  left: 10%;
  right: 10%;
}
.error-msg {
  color: #db2269;
  font-size: 0.625em;
  float: center;
  font-family: Open Sans;
  font-style: normal;
}
.error-ref {
  color: #db2269;
  font-size: 0.625em;
  float: left;
  font-family: Open Sans;
  font-style: normal;
  margin-top: 2px;
}
.error {
  color: #db2269;
  font-size: 0.625em;
  float: left;
  font-family: Open Sans;
  font-style: normal;
}
.signup-error-top {
  margin-top: 11px;
  font-family: Open Sans;
  font-style: normal;
}
.flex {
  display: flex;
}

p {   
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}

h5 {
  font-size: 1.25rem !important;
  margin-top: 0 !important;
  margin-bottom: 0.5rem !important;
  font-family: Raleway, sans-serif !important;
}
.container_class{
  width: 100%;
  height: 7vh;
  background-color: white;
  border-radius: 10px;
  font-family: Open Sans;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid lightgrey;
}
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}
.star-rating {
  display: flex;
  justify-content: center;
  align-items: center;
}

.star {
  font-size: 17px; /* Adjust size as needed */
  color: gray; /* Default color for empty stars */
}

.star.filled {
  color: gold; /* Color for filled stars */
}

.star.half-filled {
  color: gold; /* Color for half filled stars */
  background: linear-gradient(90deg, gold 50%, gray 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.star.quarter-filled {
  color: gold; /* Color for quarter filled stars */
  background: linear-gradient(90deg, gold 25%, gray 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}