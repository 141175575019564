
.tracking-heading {
    font-size: 16px;
    font-weight: 600;
    font-family: Open Sans;
    color: #FF942C;
}

.tracking-container {
    width: 100%;
    padding: 20px;
    margin: 0;
    height: 76vh;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.header {
    display: flex;
    justify-content: space-between;
    font-family: Open Sans;
}
.est-date{
    font-family: Open Sans;
    font-weight: 600;
    font-size: 18px;  
}
.t-container {
    width: 80%;
    padding: 20px 0;
    margin:10px auto;
    position: relative;
    overflow: hidden;
}

.t-container:before {
    content: '';
    position: absolute;
    top: 10%;
    left: 50%;
    margin-left: -2.5px;
    width: 3px;
    height: 65%;
    background: #31197c;
    z-index: 1
}

.timeline-block {
    width: -webkit-calc(50% + 8px);
    width: -moz-calc(50% + 8px);
    width: calc(50% + 8px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    clear: both;
}

.timeline-block-right {
    float: right;
}

.timeline-block-left {
    float: left;
    direction: rtl;
    margin-left: -2px;
}

.marker {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #31197C;
    background: #31197c;
    margin-top: 10px;
    z-index: 9999
}

.marker-empty {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #31197C;
    background: #F5F7FA;
    margin-top: 10px;
    z-index: 9999
}

.timeline-content {
    width: 100%;
    padding: 0 10px;
    color: black;
    height: 6rem;
}

.timeline-content h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 500
}

.timeline-content span {
    font-size: 13px;
    color: black;
}

/* .timeline-content p {
    font-size: 14px;
    line-height: 1.5em;
    word-spacing: 1px;
 } */
.timeline-text {
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: normal;
    color: black;
    margin-top: -15px;
}

.time-img {
    width: 30px;
    margin-bottom: 5px;
}