.pricing-main {
    width: 100%;
    height: 180vw;
}

.pricing-left-right {
    /* margin-left:1.6rem;
    margin-right:0.5rem; */
    margin-left: 10%;
    margin-right: 10%;
}

.no-scroll-pricing {
    width: 310px;
    height: 430px;
    margin-top: 0.8rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
}

.scroll-pricing {
    height: 360px;
    width: 23vw;
    overflow: hidden;
    padding: 6px;
    border-radius: 10px;
    color: black;
    box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.2);
}

.scroll-pricing:hover .pricing-inner-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
    font-size: 13px;
    letter-spacing: 0.8px;
    color: black;
    animation: preloader ease-in-out alternate;
}

.pricing-inner-text {
    margin-top: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
    font-size: 13px;
    letter-spacing: 0.8px;
    display:flex;
}

.scroll-pricing:hover .pricing-inner-text:nth-child(1) {
    animation: preloader 1s ease-in-out alternate;

}

.scroll-pricing:hover .pricing-inner-text:nth-child(2) {
    animation: preloader 1s ease-in-out alternate;
    color: black;
}

.scroll-pricing:hover .pricing-inner-text:nth-child(3) {
    animation: preloader 1s ease-in-out alternate;
    animation-delay: 1s;
    color: black;
}

.scroll-pricing:hover .pricing-inner-text:nth-child(4) {
    animation: preloader 1s ease-in-out alternate;
    animation-delay: 2s;
    color: black;
}

.scroll-pricing:hover .pricing-inner-text:nth-child(5) {
    animation: preloader 1s ease-in-out alternate;
    animation-delay: 3s;
    color: black;
}

.scroll-pricing:hover .pricing-inner-text:nth-child(6) {
    animation: preloader 1s ease-in-out alternate;
    animation-delay: 4s;
    color: black;
}

.scroll-pricing:hover .pricing-inner-text:nth-child(7) {
    animation: preloader 1s ease-in-out alternate;
    animation-delay: 5s;
    color: black;
}

.scroll-pricing:hover .pricing-inner-text:nth-child(8) {
    color: black;
    animation: preloader 1s ease-in-out alternate;
    animation-delay: 6s;
}

.scroll-pricing:hover .pricing-inner-text:nth-child(9) {
    color: black;
    animation: preloader 1s ease-in-out alternate;
    animation-delay: 7s;
}
@keyframes preloader {

    100% {
        color: black;
        font-weight: 800;
    }
}


.free {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.8px;
    color: #1E2022;
}

.monthly {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.8px;
    color: #1E2022;
}

.react-icon {
    color: green;
    margin-top: 2px;
}

.react-icon-y {
    color: white;
}

.react-icon-red {
    color: red;
}

/* 
.pricing-ml {
    margin-left: 0px;
    margin-right: 0px;
} */



/* .pricing-inner-text:hover:nth-child(2) {
    cursor: pointer;
    animation-name: example;
    animation-duration: 4s;
    animation-iteration-count: 3;
    animation: example 8s steps(60, end);
}



@keyframes example {
    from {
        width: 0%;
        color: rgba(17, 16, 17, 0.71);
        font-weight: 600;
    }

    to {
        width: 100%;
        color: rgba(17, 16, 17, 0.98);
        font-weight: 800;
        ;
    }
} */

.price {
    font-weight: 500;
    color: black;
}

/* 
.price:hover {
    transform: scale(1.04);
    color: rgba(1, 1, 1, 1);
} */

.money-color {
    color: #31197C;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.8px;
    position: absolute;
    bottom: 8%;
    left: 25%;
    right: 25%;
}

.rupee {
    font-size: 22px;
}

.scroll-pricing:hover {
    transform: scale(1.08);
    border: 2px solid #31197C;
    animation: preloader 2s ease-in-out alternate infinite;
}

/* &:scroll-pricing .pricing-inner-text:nth-child(1) {
        animation: preloader 6s ease-in-out alternate infinite;
    } */


/* .parent {
    background: white;
    pointer-events: none;

    &:hover {
        background: gray;
    }

    a {
        pointer-events: auto;

        &:hover {
            color: red;
        }
    }
} */



.mb {
    margin-bottom: 3.5rem;
}

@media screen and (max-width:639px) {
    .scroll-pricing {
        height: 360px;
        width: 100%;
        overflow: hidden;
        padding: 6px;
        border-radius: 10px;
        color: black;
        box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.2);
    }

    .scroll-pricing:hover .pricing-inner-text {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.8px;
        color: black;
        animation: preloader ease-in-out alternate;
    }
}