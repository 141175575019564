/* .NavBarItems{
    background: linear-gradient(90deg, rgb(110,94,254) 0%,rgba(73,63,25)100%);
    display:flex;
    height: 5rem;
    justify-content: center;
    align-items: center;
    font-size:1.2rem;
    margin-top: 5rem;
} */
.navbar-logo {
    margin-left: 2.8rem;
    cursor: pointer;
    width: 8%;
    margin-bottom: -3.0rem;
    margin-top: 0rem;
}

.nav-height {
    height: 3.5rem;
}

.nav-margin-right {
    margin-right: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    padding: 10px;
    list-style: none;
    text-align: center;
    text-decoration: none;
    width: 100vw;
    justify-content: end;
    margin-right: 2rem;
    margin-top:0.2%;
    font-size: small;
}

.nav-links {
    color: black;
    transition: all 0.2s ease-out;
    padding: 1.2rem;
    font-family: Open Sans !important;
}

.nav-links-signUpbtn {
    background-color: #31197C !important;
    width: 6rem;
    height: 30px;
    border-color: #31197C !important;
    border-radius: 6px;
    text-align: center;
    font-family: Open Sans !important;
    margin: auto;
    margin-left: 2.2rem;
    padding-top: 4px;
    color: white;
    font-size: small;
}

.nav-links-signUpbtn:hover {
    background-color: #31197C;
    border-color: #31197C;
    color: white;
}

.nav-links:hover {
    color: #31197C;
    transition: all 0.2s ease-out;
    text-decoration: none;
}

.fa-bars {
    color: black;
    margin-top: -0.5rem;
}
.menu_icon {
    display: none;
}

.menu_icon i {
    position: absolute;
    top: 10%;
    right: 15px;
    -webkit-transform: translateY(75%);
    -ms-transform: translateY(75%);
    transform: translateY(75%);
    z-index: 1;
}

@media screen and (max-width:960px) {
    .NavBarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 50px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background-color: rgb(231, 228, 228);
        left: 0;
        top: 4rem;
        height: auto;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 0.5rem;
        width: 100%;
        display: table;
        font-family: Open Sans !important;
    }

    .navbar-logo {
        position: absolute;
        left: -3.5rem;
        margin-top: -2.8rem;
        transform: translate(25%, 50%);
        width: 18%;
    }
    .nav-height {
        height: 3.5rem;
        box-shadow: 0px 1px 10px #999;
    }

    .menu_icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .menu_icon i {
        position: absolute;
        top: 10%;
        right: 15px;
        -webkit-transform: translateY(75%);
        -ms-transform: translateY(75%);
        transform: translateY(75%);
        z-index: 1;
    }

    .fa-times {
        color: black;
        margin-top: -0.5rem;
    }
    .nav-links-signUpbtn {
        display: block;
        text-align: center;
        margin: auto;
        border-radius: 6px;
        width: 8rem;
        height: auto;
        background: #31197C;
        text-decoration: none;
        font-family: Open Sans !important;
        color: white;
        border-color: #31197C;
        padding-top: 5px;
        padding-bottom: 9px;
    }
}

@media screen and (max-width:639px) {
    .NavBarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 50px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background-color: rgb(231, 228, 228);
        left: 0;
        top: 4rem;
        height: auto;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 0.5rem;
        width: 100%;
        display: table;
        font-family: Open Sans !important;
    }

    .navbar-logo {
        position: absolute;
        left: -3.5rem;
        margin-top: -2.2rem;
        transform: translate(25%, 50%);
        width: 22%;
    }
    .nav-height {
        height: 3.5rem;
        box-shadow: 0px 1px 10px #999;
    }

    .menu_icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .menu_icon i {
        position: absolute;
        top: 10%;
        right: 15px;
        -webkit-transform: translateY(75%);
        -ms-transform: translateY(75%);
        transform: translateY(75%);
        z-index: 1;
    }

    .fa-times {
        color: black;
        margin-top: -0.5rem;
    }
    .nav-links-signUpbtn {
        display: block;
        text-align: center;
        margin: auto;
        border-radius: 6px;
        width: 8rem;
        height: auto;
        background: #31197C;
        font-family: Open Sans !important;
        text-decoration: none;
        color: white;
        border-color: #31197C;
        padding-top: 5px;
        padding-bottom: 9px;
    }
}