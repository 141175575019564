/* .circle-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
}

.horizontal-line {
    position: absolute;
    top: 30%;
    left: 15%;
    right: 0;
    height: 2px;
    background-color: #000;
    z-index: 1;
    width: 70%;
}

.circle-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle-p {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #31197C;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: background-color 0.3s;
}

.circle-p.active {
    background-color: #31197C;
    color: #fff;
}

.circle-label {
    margin-top: 10px;
    font-size: 16px;
    font-family: Open Sans;
}

.circle-label.active {
    font-weight: bold;
    color: #31197C;
}

.circle-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    font-family: Open Sans;
}

.circle-info {
    font-size: 18px;
    text-align: left;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-top: 10px;
    margin: 5px;
    width: 80%;
}

.line-lp {
    height: 45px;
    border: none;
    border-radius: 40px;
    padding: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);

}

.line-padding {
    padding: 8px 0;
}

.activeCircle-img {
    width: 57px;
    margin-top: -6px;
    margin-left: -5px;
}

.popup-img-border {
    border-radius: 10px;
    padding: 10px;
    border: 2px solid #31197C;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);
}


@media (max-width: 768px) {
    .circle-container {
        display: flex;
    }

    .horizontal-line {
        position: absolute;
        top: 27%;
        left: 15%;
        right: 0;
        height: 2px;
        background-color: #000;
        z-index: 1;
        width: 70%;
    }

    .circle-p {
        margin-bottom: 20px;
    }

    .circle-label {
        margin-top: -10px;
        font-size: 16px;
        font-family: Open Sans;
    }

    .circle-label.active {
        font-weight: bold;
        color: #31197C;
    }

    .circle-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        font-family: Open Sans;
    }

    .circle-info {
        font-size: 18px;
        text-align: left;
        padding: 10px;
        border: none;
        border-radius: 5px;
        background-color: #f9f9f9;
        margin-top: 10px;
        margin: 0px;
        width: 100%;
    }

    .line-lp {
        height: auto;
        border: none;
        border-radius: 40px;
        padding: 0;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);

    }

    .line-padding {
        padding: 2px 0;
    }

    .activeCircle-img {
        width: 70px;
        margin-top: -1px;
        margin-left: -7px;
    }

    .popup-img-border {
        border-radius: 10px;
        padding: 10px;
        border: 2px solid #31197C;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);
    }
} */


/* .circle-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
    flex-wrap: wrap;
}

.horizontal-line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 2px;
    background-color: #ccc;
    z-index: -1;
} */
.loading-animation {
    animation: loadingAnimation 1s ease-in-out;
  }
  
  @keyframes loadingAnimation {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
 .bold-text{
    font-weight: 600;
 }
.signup-text {
    font-size: 1.5rem;
    font-weight: bold;
}

.close-icon {
    cursor: pointer;
}

.circle-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    margin-top:3.5rem;
}

.horizontal-line {
    position: absolute;
    top: 25%;
    left: 15%;
    right: 0;
    height: 2px;
    background-color: #000;
    z-index: 1;
    width: 70%;
}

.circle-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
    min-width: 70px;
    margin-bottom: 10px;
}

.circle-p {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #31197C;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    z-index: 1;
}

.circle-p.active {
    background-color: #31197C;
    color: #fff;
}

.circle-label {
    font-size: 0.9rem;
    text-align: center;
    max-width: 80px;
    font-family: Open Sans;
}

.circle-label.active {
    font-weight: bold;
}

.circle-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
    margin: 5px 13%;
}

.circle-info {
    width: 100%;
    /* max-height: 500px; */
    margin: 5px 3rem;

    /* Fixed height for the modal content */
    /* overflow-y: auto; */
    /* Add scroll if content overflows */
}

.info-row {
    display: flex;
    width: 70%;
    flex-wrap: wrap;
}

.text-container {
    flex: 1;
    padding-right: 20px;
    box-sizing: border-box;
}

.line-lp {
    height: 40px;
    border: none;
    border-radius: 40px;
    padding: 0;
    margin: 10px;
    font-family: Open Sans;
    display: flex;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);

}

/* .line-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
} */

.line-padding {
    padding: 8px 0;
    font-size: 1rem;
    cursor: pointer;
}

.activeCircle-img {
    width: 50px;
    margin-top: -5px;
    margin-left: -5px;
}
.activeCircle1-img {
    width: 52px;
    margin-top: -5px;
    margin-left: -5px;
}
/* .image-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 220px;
    flex-shrink: 0;
} */

.popup-img-border {
    border: 1px solid #31197C;
    padding:2rem 1.4rem;
    border-radius: 10px;
    max-width: 260px;
    max-height: 430px;
    margin-left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 15px rgba(0,0,0,0.1), 1px 1px 3px rgba(0,0,0,0.2); 
}

.popup-img-border img {
    max-width: 100%;
    max-height: 100%;
}

.mod-height {
    height: 600px;
}

/* Media Queries for Mobile Responsiveness */

@media (max-width: 768px) {

    .text-container {
        width: 100%;
        padding-right: 0;
    }

    .image-container {
        width: 100%;
        margin-top: 3px;
    }

    .circle-wrapper {
        margin-bottom: 15px;
    }

    .circle-container {
        display: flex;
        margin-top:0.1rem;
    }

    .circle-info {
        width: 100%;
        margin: 2px 2px 2px -10px;
    }

    .info-row {
        display: flex;
        width: 92%;
        flex-wrap: wrap;
    }

    .circle-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        width: 100%;
        margin: 5px 3%;
    }

    .horizontal-line {
        position: absolute;
        top: 23%;
        left: 15%;
        right: 0;
        height: 2px;
        background-color: #000;
        z-index: 1;
        width: 70%;
    }

    .circle-p {
        width: 40px;
        height: 40px;
    }

    .circle-label {
        font-size: 1rem;
    }

    .line-padding {
        padding: 2px 0;
        cursor: pointer;
    }

    .line-lp {
        width: 100%;
        height: auto;
        border: none;
        border-radius: 40px;
        padding: 0;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);
    }

    .activeCircle-img {
        width: 50px;
        margin-top: -1px;
        margin-left: -5px;
    }
    .activeCircle1-img {
        width: 52px;
        margin-top: 1px;
        margin-left: -5px;
    }
    .mod-height {
        height: auto;
    }

    .popup-img-border {
        border: 1px solid #31197C;
        padding:2rem 1rem;
        border-radius: 10px;
        max-width: 250px;
        max-height: 420px;
        margin-left:20px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: inset 0 0 15px rgba(0,0,0,0.1), 1px 1px 3px rgba(0,0,0,0.2); 
        /* border: 1px solid #31197C;
        padding: 10px;
        border-radius: 10px;
        margin-left: 1rem;
        max-width: 220px;
        max-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: inset 0 0 30px rgba(0,0,0,0.1), 1px 1px 3px rgba(0,0,0,0.2);  */
    }
 

}